import { Container } from 'src/components/Container/styled';
import { useState } from 'react';
import iphoneMockupImage from 'src/assets/img/iphone-mockup-desktop.webp';
import {
  ContentDescriptionStyled,
  ContentStyled,
  ContentTitleStyled,
  ContentWrapperStyled,
  ImageStyled,
  ContentContainerStyled,
} from './styled';

import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/play-store.svg';
import { ReactComponent as AppStoreIcon } from 'src/assets/icons/app-store.svg';
import { ReactComponent as DownloadApkIcon } from 'src/assets/icons/download-apk.svg';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

export const LaunchBoxProgram = () => {
  const navigate = useNavigate();
  return (
    <Container id="Bonus">
      <ContentWrapperStyled>
        <ContentContainerStyled>
          <ContentStyled>
            <ContentTitleStyled>
              Download <span style={{ color: '#AADE16' }}>HAQABI</span> Wallet
              🚀
            </ContentTitleStyled>
            <ContentDescriptionStyled>Exciting news!</ContentDescriptionStyled>
            <ContentDescriptionStyled style={{ color: '#AADE16' }}>
              Our official app is now available on Google Play and App Store!
            </ContentDescriptionStyled>
            <ContentDescriptionStyled>
              {`
              Explore all the features designed to enhance your experience.
              Don't miss out on this opportunity to streamline your interactions
              with us and unlock a world of crypto at your fingertips.
              `}
            </ContentDescriptionStyled>
            <ContentDescriptionStyled>
              Download application from Google Play store or download .apk file
              and enjoy your user experience!
            </ContentDescriptionStyled>
            <DownloadApkWrapper>
              <DownloadElementWrapper
                href="https://play.google.com/store/apps/details?id=com.haqabi.mobilewallet"
                target="_blank">
                <PlayMarketIcon width={'2.222vw'} height={'2.222vw'} />
                <DowloadText>Google play</DowloadText>
              </DownloadElementWrapper>
              <DownloadElementWrapper
                href="https://apps.apple.com/app/haqabi-wallet/id6477534740"
                target="_blank">
                <AppStoreIcon width={'2.222vw'} height={'2.222vw'} />
                <DowloadText>App Store</DowloadText>
              </DownloadElementWrapper>
              <DownloadElementWrapper onClick={() => navigate('/download-apk')}>
                <DowloadText>APK</DowloadText>
                <DownloadApkIcon />
              </DownloadElementWrapper>
            </DownloadApkWrapper>
          </ContentStyled>
        </ContentContainerStyled>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
            paddingLeft: '3.8vw',
            flex: 1,
          }}>
          <ImageStyled alt="haqqabi-mockup" src={iphoneMockupImage} />
        </div>
      </ContentWrapperStyled>
    </Container>
  );
};

const DownloadElementWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.556vw;
  border-color: #6a7484;
  border-width: 1px;
  border-style: solid;
  background-color: #29313e;
  gap: 1.389vw;
  height: 4.653vw;
  cursor: pointer;
  flex: 1;
  &:hover {
    opacity: 0.7;
  }
`;

const DowloadText = styled.p`
  font-size: 0.972vw;
  line-height: 1vw;
  color: #eaedee;
  font-family: Inter, sans-serif;
  white-space: nowrap;
`;

const DownloadApkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.389vw;
`;
