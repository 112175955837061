import styled from 'styled-components';
import featureBackground from 'src/assets/img/features-background.png';

export const TitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin: 17.361vw 0 2.5vw;
  font-size: 2.778vw;
`;

export const ContentContainerStyled = styled.div`
  padding: 2.5vw 0 2.5vw 2.5vw;
  max-width: 40%;
`;

export const ContentWrapperStyled = styled.div`
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(7.083583831787109px);
  background-image: url(${featureBackground});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  border: 0.069vw solid #525a67;
  border-radius: 1.111vw;
  width: 100%;
  height: 100%;
`;

export const ContentTitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 2.222vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.278vw;
  letter-spacing: 0.01vw;
  margin: 1.667vw 0 1.25vw;
`;

export const ContentDescriptionStyled = styled.p`
  color: #979eaa;

  font-family: Inter, sans-serif;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.01vw;
`;

export const BlockWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.667vw;
  margin-top: 1.667vw;
`;

export const BoxDescriptionStyled = styled.p`
  color: #979eaa;
  font-family: Inter, sans-serif;
  font-size: 1.16vw;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const BlockStyled = styled.div`
  width: 100%;
  border-radius: 0.833vw;
  border: 0.069vw solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(9.5px);
  padding: 1.667vw;
`;

export const ContentSubtitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 1.66vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2.278vw;
  letter-spacing: 0.01vw;
  margin: 1.25vw 0 6px;
`;
