import { Container } from 'src/components/Container/styled';
import styled from 'styled-components';
import { ReactComponent as TwitterIcon } from 'src/assets/icons/twitter.svg';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram.svg';
import { ReactComponent as DiscordIcon } from 'src/assets/icons/discord.svg';
import { useDeviceType } from 'src/hooks/useDeviceType';
const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 19.733vw 0;

  @media (min-width: 769px) {
    margin: 6.944vw 0;
  }
`;
const TitleStyled = styled.p`
  color: white;
  font-family: Inter, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.053vw;
  @media (min-width: 769px) {
    color: #eaedee;
    font-family: Inter, sans-serif;
    font-size: 1.389vw;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.01vw;
  }
`;
const LinkContainerStyled = styled.div`
  display: flex;
  gap: 2.133vw;
  margin-bottom: 2.133vw;
  @media (min-width: 769px) {
    gap: 0.833vw;
    margin-bottom: 1.389vw;
  }
`;

export const SocialMediaLinks = () => {
  const { isMobile } = useDeviceType();
  const size = isMobile ? '13.867vw' : '5vw';
  const gap = isMobile ? '2.133vw' : '1.389vw';
  return (
    <Container>
      <ContentWrapperStyled>
        <LinkContainerStyled style={{ display: 'flex', gap }}>
          <a href="https://twitter.com/HaqabiHQ">
            <TwitterIcon
              style={{
                width: size,
                height: size,
                cursor: 'pointer',
                color: 'transparent',
              }}
            />
          </a>
          <a href="https://t.me/haqabi">
            <TelegramIcon
              style={{
                width: size,
                height: size,
                cursor: 'pointer',
                color: 'transparent',
              }}
            />
          </a>
          <a href="https://discord.gg/kXeefuKPmQ">
            <DiscordIcon
              style={{
                width: size,
                height: size,
                cursor: 'pointer',
                color: 'transparent',
              }}
            />
          </a>
        </LinkContainerStyled>
        <TitleStyled>Join the Community</TitleStyled>
      </ContentWrapperStyled>
    </Container>
  );
};
