import { ReactComponent as Icons } from 'src/assets/icons/icons.svg';
import {
  DescriptionTextStyled,
  IconsInfoTextStyled,
  MainStyled,
  MainTextStyled,
  NetworkAdditionStyled,
  StoreItem,
  StoreItemsWrapper,
  StoreText,
} from './styled';
import { Container } from 'src/components/Container/styled';
import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/play-store.svg';
import { ReactComponent as AppStoreIcon } from 'src/assets/icons/app-store.svg';

export const AboutUsDesktop = () => {
  return (
    <Container>
      <MainStyled>
        <MainTextStyled>Where Security Meets Simplicity</MainTextStyled>
        <DescriptionTextStyled>
          <span style={{ color: 'var(--color-primary-5)' }}>Haqabi</span> #1
          decentralized crypto wallet
        </DescriptionTextStyled>
        <NetworkAdditionStyled>
          <Icons width="11.389vw" height="2.5vw" />
          <IconsInfoTextStyled $isMobile={false}>and more</IconsInfoTextStyled>
        </NetworkAdditionStyled>

        <StoreItemsWrapper>
          <StoreItem
            href="https://play.google.com/store/apps/details?id=com.haqabi.mobilewallet"
            target="_blank">
            <PlayMarketIcon width={'2.222vw'} height={'2.222vw'} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '.278vw',
                alignItems: 'flex-start',
              }}>
              <StoreText>Google play</StoreText>
            </div>
          </StoreItem>
          <StoreItem
            href="https://apps.apple.com/app/haqabi-wallet/id6477534740"
            target="_blank">
            <AppStoreIcon width={'2.222vw'} height={'2.222vw'} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '.278vw',
                alignItems: 'flex-start',
              }}>
              <StoreText>App Store</StoreText>
            </div>
          </StoreItem>
        </StoreItemsWrapper>
      </MainStyled>
    </Container>
  );
};
