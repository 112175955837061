import styled from 'styled-components';

import 'swiper/css';
import 'swiper/css/pagination';

export const SwapsWrapper = styled.div`
  padding: 8vw 0;
  background: linear-gradient(
    104.69deg,
    rgba(255, 255, 255, 0.05) 15.53%,
    rgba(255, 255, 255, 0) 87.71%
  );
  border: solid rgba(82, 90, 103, 1) 1px;
  border-radius: 3.2vw;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100%;
  }

  .swiper-pagination-bullet {
    width: 2.133vw;
    height: 2.133vw;
    border: 0.267vw solid var(--color-neutral-7);
    border-radius: 50%;
    background-color: var(--color-neutral-7);
  }

  .swiper-pagination-bullet-active {
    width: 2.133vw;
    height: 2.133vw;
    background-color: #c2ee4e;
  }
`;

export const SlideTitle = styled.p`
  font-size: 5.333vw;
  color: var(--color-neutral-1);
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

export const ImageStyled = styled.img`
  width: 100%;
`;
