export default {
  errors: {
    errorHappened: {
      en: 'Error happened!',
      pt: 'Ocorreu um erro. ',
      id: 'Terjadi kesalahan!',
      vi: 'Đã xảy ra lỗi!',
      ms: 'Ralat berlaku!',
      bn: 'ত্রুটি ঘটেছে!',
      pa: 'ਗਲਤੀ ਆਈ ਹੈ!',
      tr: 'Bir hata oluştu',
    },
    insufficientBalance: {
      en: 'Insufficient balance',
      pt: 'Saldo insuficiente.',
      id: 'Saldo tidak mencukupi',
      vi: 'Số dư không đủ',
      ms: 'baki tidak mencukupi',
      bn: 'অপর্যাপ্ত ব্যালেন্স',
      pa: 'ਨਾਕਾਫ਼ੀ ਬਕਾਇਆ',
      tr: 'Yetersiz bakiye, nolacak. ',
    },
  },
  completed: {
    en: 'Completed',
    pt: 'Concluído',
    id: 'Completed',
    vi: 'Đã hoàn thành',
    ms: 'Selesai',
    bn: 'সম্পন্ন',
    pa: 'ਕੰਪਾਇਲ',
    tr: 'Tamamlandı',
  },
  back: {
    en: 'Back',
    pt: 'Voltar',
    id: 'Kembali',
    vi: 'Quay lại',
    ms: 'Kembali',
    bn: 'ফেরত যান',
    pa: 'ਵਾਪਸ ਜਾਓ',
    tr: 'Geri',
  },
  cancel: {
    en: 'Cancel',
    pt: 'Cancelar',
    id: 'Batal',
    vi: 'Hủy',
    ms: 'Batal',
    bn: 'বাতিল করুন',
    pa: 'ਰੱਦ ਕਰੋ',
    tr: 'İptal',
  },
  continue: {
    en: 'Continue',
    pt: 'Continuar',
    id: 'Lanjutkan',
    vi: 'Tiếp tục',
    ms: 'Teruskan',
    bn: 'জারি রাখুন',
    pa: 'ਜਾਰੀ ਰੱਖੋ',
    tr: 'Devam et',
  },
  export: {
    en: 'Export',
    pt: 'Exportar',
    id: 'Ekspor',
    vi: 'Trích xuất',
    ms: 'Eksport',
    bn: 'রপ্তানি ',
    pa: 'ਐਕਸਪੋਰਟ',
    tr: 'Dışa Aktar',
  },
  close: {
    en: 'Close',
    pt: 'Fechado',
    id: 'Tutup',
    vi: 'Đóng',
    ms: 'Tutup',
    bn: 'বন্ধ করুন',
    pa: 'ਬੰਦ',
    tr: 'Kapat',
  },
  log_in: {
    en: 'Log in',
    pt: 'Iniciar sessão',
    id: 'Masuk',
    vi: 'Đăng nhập',
    ms: 'Log masuk',
    bn: 'লগ ইন করুন',
    pa: 'ਲਾਗ ਇਨ',
    tr: 'Oturum aç',
  },
  contactSupport: {
    en: 'Contact support',
    pt: 'Contactar o Suporte',
    id: 'Hubungi dukungan',
    vi: 'Liên hệ bộ phận hỗ trợ',
    ms: 'Hubungi Bantuan',
    bn: 'সহায়তা টিমের সাথে যোগাযোগ করুন',
    pa: 'ਸਹਾਇਤਾ ਨਾਲ ਸੰਪਰਕ ਕਰੋ',
    tr: 'Destek ile iletişime geç',
  },
  invalidEmail: {
    en: 'Invalid email address',
    pt: 'Endereço de e-mail inválido',
    id: 'Alamat email tidak valid',
    vi: 'Địa chỉ email không hợp lệ',
    ms: 'Alamat e-mel tidak sah',
    bn: 'অকার্যকর ইমেইল ঠিকানা',
    pa: 'ਗਲਤ ਈ- ਮੇਲ ਐਡਰੈੱਸ',
    tr: 'Geçersiz e-posta adresi',
  },
  backToSecurity: {
    en: 'Back to secutity',
    pt: 'Voltar à secutidade',
    id: 'Kembali ke kerahasiaan',
    vi: 'Quay lại trang bảo mật',
    ms: 'Kembali ke ketenteraman',
    bn: 'সিকিউটিটিতে ফিরে যান',
    pa: 'ਸਿਕਿਓਰਿਟੀ \'ਤੇ ਵਾਪਸ ਜਾਓ',
    tr: 'Güvenliğe geri dön',
  },
  passwordMatch: {
    en: 'Passwords match',
    pt: 'As senhas correspondem',
    id: 'Kata sandi tidak cocok!',
    vi: 'Máº­t kháº©u trÃ¹ng.',
    ms: 'Padanan kata laluan',
    bn: 'পাসওয়ার্ড মিলছে',
    pa: 'ਪਾਸਵਰਡ ਮਿਲਦਾ ਹੈ',
    tr: 'Parolalar eşlendi',
  },
  passwordDontMatch: {
    en: 'Password doesn\'t match',
    pt: 'Palavras-passe não coincidem',
    id: 'Kata sandi tidak cocok',
    vi: 'Mật khẩu không khớp',
    ms: 'Kata laluan semasa tidak sepadan',
    bn: 'বর্তমান পাসওয়ার্ড মেলে না',
    pa: 'ਪਾਸਵਰਡ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ',
    tr: 'Şifre eşleşmiyor',
  },
  fillRequiredFields: {
    en: 'Fill the required fields',
    pt: 'Preenche todos os campos necessários.',
    id: 'Isi semua bidang yang dibutuhkan.',
    vi: 'Điền vào các trường bắt buộc',
    ms: 'Isi medan yang diperlukan',
    bn: 'প্রয়োজনীয় ক্ষেত্রগুলি পূরণ করুন',
    pa: 'ਲੋੜੀਂਦੇ ਖੇਤਰ ਭਰੋ',
    tr: 'Gerekli alanları doldurun.',
  },
  securityVerification: {
    en: 'Security verification',
    pt: 'Verificação de Segurança',
    id: 'Verifikasi Keamanan',
    vi: 'Xác minh Bảo mật',
    ms: 'Pengesahan Keselamatan',
    bn: 'নিরাপত্তা যাচাইকরণ',
    pa: 'ਸੁਰੱਖਿਆ ਤਸਦੀਕ',
    tr: 'Güvenlik Doğrulaması',
  },
  canLoginWithNewPassword: {
    en: 'You can now log in to your account using the new password.',
    pt: 'Agora pode iniciar sessão na sua conta utilizando a nova palavra-passe.',
    id: 'Anda sekarang dapat masuk ke akun Anda menggunakan kata sandi baru.',
    vi: 'Bây giờ bạn có thể đăng nhập vào tài khoản của mình bằng mật khẩu mới.',
    ms: 'Anda kini boleh log masuk ke akaun anda menggunakan kata laluan baharu.',
    bn: 'আপনি এখন নতুন পাসওয়ার্ড ব্যবহার করে আপনার অ্যাকাউন্টে লগ ইন করতে পারেন ।',
    pa: 'ਹੁਣ ਤੁਸੀਂ ਨਵੇਂ ਪਾਸਵਰਡ ਦੀ ਵਰਤੋਂ ਕਰਕੇ ਆਪਣੇ ਖਾਤੇ ਵਿੱਚ ਲੌਗਇਨ ਕਰ ਸਕਦੇ ਹੋ.',
    tr: 'Artık yeni şifreyi kullanarak hesabınıza giriş yapabilirsiniz.',
  },
  successfullySent: {
    en: 'Successfully sent!',
    pt: 'Enviado com sucesso!',
    id: 'Berhasil dikirim',
    vi: 'Đã gửi thành công',
    ms: 'Berjaya dihantar!',
    bn: 'সাফল্যের সাথে প্রেরণ করা হয়েছে',
    pa: 'ਸਫਲਤਾਪੂਰਕ ਭੇਜਿਆ ਗਿਆ!',
    tr: 'Mesajınız başarıyla gönderildi!',
  },
  contactUs: {
    en: 'Contact us',
    pt: 'Entre em contato',
    id: 'Hubungi kami',
    vi: 'Liên hệ với chúng tôi',
    ms: 'Hubungi kami',
    bn: 'আমাদের সাথে যোগাযোগ করুন',
    pa: 'ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ',
    tr: 'Bizimle iletişime geçin',
  },
  enterYourEmail: {
    en: 'Enter your email',
    pt: 'Introduza o seu e-mail',
    id: 'Masukkan email Anda',
    vi: 'Nhập email của bạn',
    ms: 'Masukkan e-mel anda',
    bn: 'আপনার ইমেল ঠিকানা লিখুন',
    pa: 'ਆਪਣਾ ਈ - ਮੇਲ ਭਰੋ',
    tr: 'E-postanızı giriniz',
  },
  maxCharsName: {
    en: 'The name can\'t be more than 256 character',
    pt: 'O nome não pode ter mais de 256 caracteres',
    id: 'Nama tidak boleh lebih dari 256 karakter',
    vi: 'Tên không được quá 256 ký tự',
    ms: 'Nama tidak boleh lebih daripada 256 aksara',
    bn: 'নাম 256 অক্ষরের বেশি হতে পারে না',
    pa: 'ਨਾਮ 256 ਅੱਖਰਾਂ ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋ ਸਕਦਾ',
    tr: 'Ad 256 karakterden fazla olamaz',
  },
  maxCharsLastName: {
    en: 'The last name can\'t be more than 256 characters',
    pt: 'O sobrenome não pode ter mais de 256 caracteres',
    id: 'Nama belakang tidak boleh lebih dari 256 karakter',
    vi: 'Họ không được dài hơn 256 ký tự',
    ms: 'Nama keluarga tidak boleh lebih daripada 256 aksara',
    bn: 'নামের শেষাংশ 256 অক্ষরের বেশি হতে পারবে না',
    pa: 'ਆਖਰੀ ਨਾਮ 256 ਅੱਖਰਾਂ ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋ ਸਕਦਾ',
    tr: 'Soyad 256 karakterden uzun olamaz',
  },
  incorrectEmailFormat: {
    en: 'Incorrect email format',
    pt: 'Formato incorreto de e-mail',
    id: 'format email salah',
    vi: 'Định dạng email không đúng,',
    ms: 'Format e-mel salah',
    bn: 'ভুল ইমেল ফর্ম্যাট',
    pa: 'ਗਲਤ ਈਮੇਲ ਫਾਰਮੈਟ',
    tr: 'yanlış e-posta formatı',
  },
  checkYourProfile: {
    en: 'Check your profile',
    pt: 'Verifique o seu perfil',
    id: 'Periksa profil Anda',
    vi: 'Kiểm tra hồ sơ của bạn',
    ms: 'Semak profil anda',
    bn: 'আপনার প্রোফাইল চেক করুন',
    pa: 'ਆਪਣੀ ਪ੍ਰੋਫਾਈਲ ਦੀ ਜਾਂਚ ਕਰੋ',
    tr: 'Profilinizi kontrol edin',
  },
  googleAuthenticatorCode: {
    en: 'Enter Google Authenticator code',
    pt: 'Código do Google Authenticator/Authy',
    id: 'Google Authenticator',
    vi: 'Mã Google Authenticator',
    ms: 'Google Authenticator',
    bn: 'Google Authenticator',
    pa: 'ਗੂਗਲ ਪ੍ਰਮਾਣਕ ਕੋਡ',
    tr: 'Google Kimlik Doğrulayıcı Kodu',
  },
  login: {
    en: 'Login',
    pt: 'Iniciar sessão',
    id: 'Log masuk',
    vi: 'Đăng Nhập',
    ms: 'Log masuk',
    bn: 'লগইন করুন',
    pa: 'ਲਾਗਿਨ',
    tr: 'Oturum Aç',
  },
  confirmation: {
    en: 'Confirmation',
    pt: 'Confirmação',
    id: 'Konfirmasi',
    vi: 'Xác nhận',
    ms: 'Pengesahan',
    bn: 'নিশ্চিতকরণ',
    pa: 'ਪੁਸ਼ਟੀ',
    tr: 'Onaylama',
  },
  emailAddress: {
    en: 'Email Address',
    pt: 'Endereço e-mail',
    id: 'Alamat Email',
    vi: 'Địa chỉ email',
    ms: 'Alamat E-mel',
    bn: 'আপনার ইমেইল',
    pa: 'ਈਮੇਲ ਖਾਤਾ',
    tr: 'E-posta Adresi',
  },
  password: {
    en: 'Password',
    pt: 'Palavra-passe',
    id: 'Password',
    vi: 'Mật khẩu',
    ms: 'Kata Laluan',
    bn: 'পাসওয়ার্ড',
    pa: 'ਪਾਸਵਰਡ',
    tr: 'Parola',
  },
  registration: {
    en: 'Registration',
    pt: 'Credenciamento',
    id: 'Pendaftaran',
    vi: 'Đăng ký',
    ms: 'Pendaftaran',
    bn: 'রেজিস্ট্রেশন',
    pa: 'ਰਜਿਸਟਰੇਸ਼ਨ',
    tr: 'Kayıt',
  },
  email: {
    en: 'Email',
    pt: 'Endereço de Correio Electrónico:',
    id: 'Email',
    vi: 'Email',
    ms: 'E-mel',
    bn: 'ইমেইল',
    pa: 'ਈਮੇਲ',
    tr: 'E-posta',
  },
  requiredField: {
    en: 'Required field',
    pt: 'Campo obrigatório',
    id: 'Kolom yang wajib diisi',
    vi: 'Trường bắt buộc',
    ms: 'Medan yang diperlukan',
    bn: 'প্রয়োজনীয় ক্ষেত্র।',
    pa: 'ਲੋੜੀਂਦਾ ਫੀਲਡ',
    tr: 'Zorunlu alan',
  },
  unavailableActions: {
    withdrawal: {
      en: 'Withdrawal unavailable',
      pt: 'Saque indisponível',
      id: 'Penarikan tidak tersedia',
      vi: 'Không thể rút tiền',
      ms: 'Pengeluaran Tidak Tersedia',
      bn: 'উত্তোলন উপলভ্য নয়',
      pa: 'ਕਢਵਾਉਣਾ ਉਪਲੱਬਧ ਨਹੀਂ ਹੈ',
      tr: 'Para çekme işlemi yapılamıyor',
    },
    unavailable: {
      en: 'Unavailable',
      pt: 'Indisponível',
      id: 'Tidak tersedia',
      vi: 'Không Khả Dụng',
      ms: 'Tidak Tersedia',
      bn: 'পাওয়া যাচ্ছে না',
      pa: 'Comment',
      tr: 'Kullanım dışı',
    },
    deposit: {
      en: 'Deposit unavailable',
      pt: 'Depósito indisponível',
      id: 'Deposit tidak tersedia',
      vi: 'Không thể nạp tiền',
      ms: 'Deposit tidak tersedia',
      bn: 'ডিপোজিট পাওয়া যাচ্ছে না',
      pa: 'ਡਿਪਾਜ਼ਿਟ ਉਪਲਬਧ ਨਹੀਂ ਹੈ',
      tr: 'Para yatırma işlemi yapılamıyor',
    },
  },
  withdrawals: {
    en: 'Withdrawals',
    pt: 'Renúncias',
    id: 'Penarikan Dana',
    vi: 'Rút tiền',
    ms: 'Pengeluaran',
    bn: 'তুলে নেওয়া',
    pa: 'ਵਾਪਸੀ',
    tr: 'Para çekme',
  },
  deposits: {
    en: 'Deposits',
    pt: 'Sinais',
    id: 'Deposito',
    vi: 'Nạp tiền',
    ms: 'Deposit',
    bn: 'ডিপোজিটসমূহ',
    pa: 'ਡਿਪਾਜ਼ਿਟ',
    tr: 'Depozitolar',
  },
  enable2Fa: {
    en: 'Enable 2FA',
    pt: 'Ativar autenticação de 2 fatores',
    id: 'Aktifkan 2FA',
    vi: 'Bật 2FA',
    ms: 'Dayakan 2FA',
    bn: '2FA সক্ষম করুন',
    pa: '2FA ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ',
    tr: '2FA\'yı etkinleştir',
  },
  copied: {
    en: 'Copied',
    pt: 'Copiado',
    id: 'Disalin',
    vi: 'Đã sao chép',
    ms: 'Disalin',
    bn: 'কপি করা হয়েছে',
    pa: 'ਕਾਪੀ ਕੀਤਾ ਗਿਆ',
    tr: 'Kopyalandı',
  },
  copy: {
    en: 'Copy',
    pt: 'Copiar',
    id: 'Salin',
    vi: 'Sao chép',
    ms: 'Salin',
    bn: 'কপি করুন',
    pa: 'ਕਾਪੀ',
    tr: 'Kopyala',
  },
  trade: {
    en: 'Trade',
    pt: 'Comércio',
    id: 'Pasar',
    vi: 'Mậu dịch',
    ms: 'Perdagangan',
    bn: 'বাণিজ্য',
    pa: 'ਵਪਾਰ',
    tr: 'Ticaret',
  },
  education: {
    en: 'Education',
    pt: 'Educação',
    id: 'Pendidikan',
    vi: 'Giáo dục',
    ms: 'Pendidikan',
    bn: 'শিক্ষা',
    pa: 'ਸਿੱਖਿਆ',
    tr: 'Eğitim',
  },
  support: {
    en: 'Support',
    pt: 'Suporte.',
    id: 'Dukungan',
    vi: 'Hỗ trợ',
    ms: 'Sokongan',
    bn: 'সহায়তা',
    pa: 'ਸਹਿਯੋਗ',
    tr: 'Destek',
  },
  portfolio: {
    en: 'Portfolio',
    pt: 'Carteira',
    id: 'Portfolio',
    vi: 'Công trình',
    ms: 'Portfolio',
    bn: 'পোর্টফোলিও',
    pa: 'Portfolio',
    tr: 'Portföy',
  },
  screener: {
    en: 'Screener',
    pt: 'Triagem',
    id: 'SCREENER',
    vi: 'Bộ lọc',
    ms: 'Penyaring',
    bn: 'স্ক্রিনার',
    pa: 'ਸਕਰੀਨਰ',
    tr: 'Eleyici sorular',
  },
  markets: {
    en: 'Markets',
    pt: 'Mercados',
    id: 'Pasar',
    vi: 'Thị trường',
    ms: 'Pasaran',
    bn: 'বাজার',
    pa: 'ਬਾਜ਼ਾਰ',
    tr: 'Pazarlar',
  },
  notifications: {
    en: 'Notifications',
    pt: 'Notificações',
    id: 'Pemberitahuan',
    vi: 'Thông báo',
    ms: 'Pemberitahuan',
    bn: 'বিজ্ঞপ্তি',
    pa: 'ਨੋਟੀਫਿਕੇਸ਼ਨ',
    tr: 'Bildirimler',
  },
  showAll: {
    en: 'Show all',
    pt: 'Mostrar tudo',
    id: 'Tampilkan semua',
    vi: 'Hiển thị tất cả',
    ms: 'Papar semua',
    bn: 'সবগুলি দেখান',
    pa: 'ਸਭ ਵੇਖੋ',
    tr: 'Tümünü Göster',
  },
  or: {
    en: 'or',
    pt: 'ou',
    id: 'atau',
    vi: 'hoặc',
    ms: 'atau',
    bn: 'অথবা',
    pa: 'ਜਾਂ',
    tr: 'veya',
  },
  processing: {
    en: 'Processing',
    pt: 'A processar',
    id: 'Memroses',
    vi: 'xử lý',
    ms: 'Sedang diproses',
    bn: 'প্রক্রিয়াকরণ চলছে',
    pa: 'ਕਾਰਵਾਈ ਜਾਰੀ ਹੈJob state',
    tr: 'Hazırlanıyor',
  },
  markAllAsRead: {
    en: 'Mark all as read',
    pt: 'Marcar tudo como lido',
    id: 'tandai semua telah dibaca ',
    vi: 'Đánh dấu đã đọc tất cả',
    ms: 'Tanda semua sebagai dibaca',
    bn: 'সবগুলি পড়া হিসাবে চিহ্নিত করুন',
    pa: 'ਸਭ ਪੜ੍ਹੇ ਬਣਾਓ',
    tr: 'Hepsini okundu işaretle',
  },
  portfolioValue: {
    en: 'Portfolio Value',
    pt: 'Fair Value Carteira',
    id: 'Nilai Portofolio',
    vi: 'Giá trị danh mục đầu tư',
    ms: 'Nilai Portfolio',
    bn: 'পোর্টফোলিও ভ্যালু',
    pa: 'ਪੋਰਟਫੋਲੀਓ ਮੁੱਲ',
    tr: 'Portföy Değeri',
  },
  securityAndProfile: {
    en: 'Security & Profile',
    pt: 'Garantia',
    id: 'Security',
    vi: 'Bảo mật',
    ms: 'Keselamatan',
    bn: 'নিরাপত্তা',
    pa: 'ਸੁਰੱਖਿਆ',
    tr: 'Güvenlik',
  },
  identityVerification: {
    en: 'Identity Verification',
    pt: 'Verificação de identidade',
    id: 'Verifikasi Identitas',
    vi: 'Xác minh danh tính',
    ms: 'Pengesahan identiti',
    bn: 'পরিচয় যাচাইকরণ',
    pa: 'ਪਛਾਣ ਤਸਦੀਕ',
    tr: 'Kimlik Doğrulama ',
  },
  verification: {
    en: 'Verification',
    pt: 'Verificação',
    id: 'Verifikasi',
    vi: 'Xác thực',
    ms: 'Pengesahan',
    bn: 'যাচাইকরণ',
    pa: 'ਨਿਯਤ:',
    tr: 'Doğrulama',
  },
  apiManagement: {
    en: 'API Management',
    pt: 'Gestão da API',
    id: 'Manajemen api',
    vi: 'Quản lý API',
    ms: 'Pengurusan api',
    bn: 'API ব্যবস্থাপনা',
    pa: 'API ਪ੍ਰਬੰਧਨ',
    tr: 'API Management',
  },
  logout: {
    en: 'Log out',
    pt: 'Terminar sessão',
    id: 'Keluar',
    vi: 'Đăng Xuất',
    ms: 'Daftar keluar',
    bn: 'লগ আউট করুন',
    pa: 'ਲਾਗ ਆਉਟ',
    tr: 'Çıkış yap',
  },
  referralProgram: {
    en: 'Referral program',
    pt: 'Programa de referência',
    id: 'Program Referral',
    vi: 'Chương trình Giới thiệu',
    ms: 'Program rujukan',
    bn: 'রেফারেল প্রোগ্রাম',
    pa: 'ਰੈਫਰਲ ਪ੍ਰੋਗਰਾਮ',
    tr: 'Yönlendirme programı',
  },
  faq: {
    en: 'FAQ',
    pt: 'Perguntas frequentes',
    id: 'Pertanyaan Umum',
    vi: 'Câu hỏi thường gặp',
    ms: 'Soalan Lazim',
    bn: 'প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী',
    pa: 'ਸ- ਜ',
    tr: 'SSS',
  },
  verified: {
    en: 'Verified',
    pt: 'Verificado',
    id: 'Diverifikasi',
    vi: 'Đã kiểm',
    ms: 'Telah disahkan',
    bn: 'ভেরিফাইড',
    pa: 'ਤਸਦੀਕ ਕੀਤਾ',
    tr: 'Doğrulandı',
  },
  pending: {
    en: 'Pending',
    pt: 'Pendente',
    id: 'Pending',
    vi: 'Đang chờ',
    ms: 'Menunggu',
    bn: 'মুলতবি',
    pa: 'ਬਾਕੀ',
    tr: 'Bekleyen',
  },
  rejected: {
    en: 'Rejected',
    pt: 'Rejeitado',
    id: 'Ditolak',
    vi: 'Bị từ chối',
    ms: 'Ditolak',
    bn: 'বাতিল হয়েছে',
    pa: 'ਰੱਦ ਕਰ ਦਿੱਤਾ',
    tr: 'Reddedildi',
  },
  processed: {
    en: 'Processed',
    pt: 'Processado',
    id: 'Processed',
    vi: 'Xử lý',
    ms: 'Diproses',
    bn: 'Processed',
    pa: 'Processed',
    tr: 'İşlenmiş',
  },
  status: {
    en: 'Status',
    pt: 'Ponto da Situação',
    id: 'Status',
    vi: 'Trạng thái',
    ms: 'Status',
    bn: 'স্ট্যাটাস',
    pa: 'Status',
    tr: 'Durum',
  },
  destination: {
    en: 'Destination',
    pt: 'Destino',
    id: 'Destinasi',
    vi: 'Điểm đến',
    ms: 'Destinasi',
    bn: 'গন্তব্য',
    pa: 'ਟਿਕਾਣਾ@ title job',
    tr: 'Varış yeri',
  },
  all: {
    en: 'All',
    pt: 'Todos',
    id: 'Semua',
    vi: 'Tất cả',
    ms: 'Semua',
    bn: 'সব',
    pa: 'ਸਭ',
    tr: 'Tümü',
  },
  buy: {
    en: 'Buy',
    pt: 'Comprar',
    id: 'can u send me ur pic',
    vi: 'Mua',
    ms: 'Beli',
    bn: 'কিনুন',
    pa: 'ਖਰੀਦਣਾ',
    tr: 'Satın Al',
  },
  sell: {
    en: 'Sell',
    pt: 'Vender',
    id: 'Jual',
    vi: 'Sell',
    ms: 'Jual',
    bn: 'বিক্রি করুন',
    pa: 'ਵੇਚੋ',
    tr: 'Sat',
  },
  backToPortfolio: {
    en: 'Back to portfolio',
    pt: 'Voltar para o Portfolio',
    id: 'Kembali ke portofolio',
    vi: 'Trở lại danh mục đầu tư',
    ms: 'Kembali ke portfolio',
    bn: 'পোর্টফোলিওতে ফিরে যান',
    pa: 'ਪੋਰਟਫੋਲੀਓ \'ਤੇ ਵਾਪਸ ਜਾਓ',
    tr: 'Çalışmalara dön',
  },
  search: {
    en: 'Search',
    pt: 'Pesquisar',
    id: 'Cari',
    vi: 'Tìm kiếm',
    ms: 'Cari',
    bn: 'খুঁজুন',
    pa: 'ਖੋਜ',
    tr: 'Ara',
  },
  filters: {
    en: 'Filters',
    pt: 'Filtros',
    id: 'Tapis',
    vi: 'Bộ lọc',
    ms: 'Tapis',
    bn: 'ফিল্টার',
    pa: 'ਪਹਿਲਾਂ',
    tr: 'Filtreler',
  },
  apply: {
    en: 'Apply',
    pt: 'Aplicar',
    id: 'Terapkan',
    vi: 'Áp dụng',
    ms: 'Gunakan',
    bn: 'আবেদন',
    pa: 'ਲਾਗੂ ਕਰੋ',
    tr: 'Başvurmak',
  },
  transactionHistory: {
    en: 'Transaction history',
    pt: 'Histórico de transações',
    id: 'Riwayat transaksi',
    vi: 'Lịch sử giao dịch',
    ms: 'future orders',
    bn: 'লেনদেনের ইতিহাস',
    pa: 'ਟ੍ਰਾਂਜੈਕਸ਼ਨ ਇਤਿਹਾਸ',
    tr: 'İşlem geçmişi',
  },
  from: {
    en: 'From',
    pt: 'De',
    id: 'Dari',
    vi: 'Từ',
    ms: 'daripada',
    bn: 'থেকে',
    pa: 'ਵਲੋਂ',
    tr: 'Başlangıç',
  },
  to: {
    en: 'To',
    pt: 'Para',
    id: 'Untuk',
    vi: 'Đến',
    ms: 'Kepada',
    bn: 'বরাবর',
    pa: 'ਵੱਲ',
    tr: 'ile',
  },
  allOperations: {
    en: 'All operations',
    pt: 'Todas as operações',
    id: 'Semua operasi',
    vi: 'Tất cả hoạt động',
    ms: 'Semua operasi',
    bn: 'সমস্ত অপারেশন',
    pa: 'ਸਭ ਓਪਰੇਸ਼ਨ',
    tr: 'Tüm operasyonlar',
  },
  days90: {
    en: '90 days',
    pt: '90 dias',
    id: '90 hari',
    vi: '90 ngày',
    ms: 'hari',
    bn: '90 দিন',
    pa: '90 ਦਿਨ',
    tr: '90 gün',
  },
  allAssets: {
    en: 'All assets',
    pt: 'Todos os ativos ',
    id: 'Lihat semua Aset',
    vi: 'Xem Tất cả Tài sản',
    ms: 'Semua aset',
    bn: 'সমস্ত সম্পদ',
    pa: 'ਸਾਰੀਆਂ ਸੰਪਤੀਆਂ',
    tr: 'Tüm Varlıkları Görüntüle',
  },
  asset: {
    en: 'Asset',
    pt: 'do veículo',
    id: 'Aset',
    vi: 'Tài sản',
    ms: 'Aset',
    bn: 'জ্বালানি',
    pa: 'ਸੰਪਤੀ',
    tr: 'Varlık',
  },
  amount: {
    en: 'Amount',
    pt: 'Montante',
    id: 'Amount',
    vi: 'Số tiền',
    ms: 'Amaun',
    bn: 'পরিমাণ',
    pa: 'Quantidade:',
    tr: 'Tutar',
  },
  spot: {
    en: 'Spot',
    pt: 'À vista',
    id: 'Spot',
    vi: 'Đèn Rọi',
    ms: 'Setempat',
    bn: 'দাগ',
    pa: 'ਸਪੋਟ',
    tr: 'benek',
  },
  selectedWallet: {
    en: 'Selected wallet',
    pt: 'Carteira selecionada',
    id: 'Dompet yang dipilih',
    vi: 'Ví đã chọn',
    ms: 'Dompet yang dipilih',
    bn: 'নির্বাচিত ওয়ালেট',
    pa: 'ਚੁਣੇ ਹੋਏ ਵਾਲਿਟ',
    tr: 'Seçili cüzdan',
  },
  enterAmount: {
    en: 'Enter amount',
    pt: 'Introduzir o montante',
    id: 'Masukan jumlah',
    vi: 'Nhập số tiền',
    ms: 'Masukkan jumlah di sini',
    bn: 'পরিমাণ এন্টার করুন',
    pa: 'ਰਕਮ ਦਰਜ ਕਰੋ',
    tr: 'Tutarı girin',
  },
  address: {
    en: 'Address',
    pt: 'Endereço',
    id: 'Alamat',
    vi: 'Địa chỉ',
    ms: 'Alamat',
    bn: 'ঠিকানা',
    pa: 'ਐਡਰੈੱਸ',
    tr: 'Adres',
  },
  network: {
    en: 'Network',
    pt: 'Rede',
    id: 'Jaringan',
    vi: 'Mạng',
    ms: 'Rangkaian',
    bn: 'নেটওয়ার্ক',
    pa: 'ਨੈੱਟਵਰਕ',
    tr: 'Ağ',
  },
  wallet: {
    en: 'Wallet',
    pt: 'Carteira',
    id: 'Dompet',
    vi: 'Ví',
    ms: 'Dompet',
    bn: 'মানিব্যাগ',
    pa: 'ਵਾਲਿਟ',
    tr: 'Cüzdan',
  },
  txID: {
    en: 'TxID',
    pt: 'TxID',
    id: 'TxID',
    vi: 'TXID',
    ms: 'TxId',
    bn: 'TxID',
    pa: 'TxID',
    tr: 'TxID',
  },
  coin: {
    en: 'Coin',
    pt: 'Moeda',
    id: 'Coin',
    vi: 'Đồng xu',
    ms: 'Syiling',
    bn: 'পয়সা',
    pa: 'ਸਿੱਕਾ',
    tr: 'Madeni para',
  },
  selectedAssets: {
    en: 'Selected assets',
    pt: 'Ativos selecionados',
    id: 'Aset yang dipilih',
    vi: 'Tài sản được chọn',
    ms: 'Aset yang dipilih',
    bn: 'নির্বাচিত সম্পদ',
    pa: 'ਚੁਣੀਆਂ ਗਈਆਂ ਸੰਪਤੀਆਂ',
    tr: 'Seçili varlıklar',
  },
  allTypes: {
    en: 'All types',
    pt: 'Todos os Tipos',
    id: 'Semua tipe',
    vi: 'Toàn bộ các kiểu',
    ms: 'Semua jenis',
    bn: 'সমস্ত রকমের সফরসমূহ',
    pa: 'ਸਾਰੀਆਂ ਕਿਸਮਾਂ',
    tr: 'Tüm türler',
  },
  type: {
    en: 'Type',
    pt: 'Tipo',
    id: 'Tipe',
    vi: 'Loại',
    ms: 'Jenis',
    bn: 'ধরন ',
    pa: 'ਕਿਸਮ',
    tr: 'Türü',
  },
  deposit: {
    en: 'Deposit',
    pt: 'Depósito',
    id: 'Deposit',
    vi: 'Nạp tiền',
    ms: 'Deposit',
    bn: 'ডেপোজিট',
    pa: 'ਡਿਪਾਜ਼ਿਟ',
    tr: 'Depozito',
  },
  transfer: {
    en: 'Transfer',
    pt: 'Transferência',
    id: 'Transfer',
    vi: 'Chuyển',
    ms: 'Pemindahan',
    bn: 'স্থানান্তর',
    pa: 'ਟਰਾਂਸਫਰ( T)',
    tr: 'Devir',
  },
  reward: {
    en: 'Reward',
    pt: 'Recompensa',
    id: 'Hadiah',
    vi: 'Phần thưởng',
    ms: 'Ganjaran',
    bn: 'পুরস্কার',
    pa: 'ਇਨਾਮ',
    tr: 'Ödül',
  },
  withdraw: {
    en: 'Withdraw',
    pt: 'Levantar',
    id: 'Menarik diri dari perjanjian',
    vi: 'Rút lại',
    ms: 'Penarikan diri',
    bn: 'প্রত্যাহার করুন',
    pa: 'ਵਾਪਸ ਲਓ',
    tr: 'Çekil',
  },
  myAssets: {
    en: 'My assets',
    pt: 'Meus ativos,',
    id: 'Aset saya',
    vi: 'Tài sản của tôi',
    ms: 'Aset saya',
    bn: 'আমার সম্পদ',
    pa: 'ਮੇਰੀ ਸੰਪਤੀ',
    tr: 'Varlıklarım',
  },
  fiat: {
    en: 'Fiat',
    pt: 'Grupo Fiat',
    id: 'Fiat',
    vi: 'Định danh',
    ms: 'Fiat',
    bn: 'ফিয়াট',
    pa: 'ਫਿਏਟ',
    tr: 'Fiat',
  },
  crypto: {
    en: 'Crypto',
    pt: 'Crypto',
    id: 'Kripto',
    vi: 'Mật mãComment',
    ms: 'Comment',
    bn: 'ক্রিপ্টো',
    pa: 'ਕ੍ਰਿਪਟੋ',
    tr: 'Kripto',
  },
  avlbBalance: {
    en: 'Avlb. Balance',
    pt: 'Saldo Avlb.',
    id: 'Saldo Avlb.',
    vi: 'Số dư Avlb',
    ms: 'Baki Avlb.',
    bn: 'Avlb. ব্যালেন্স',
    pa: 'Avlb. ਸੰਤੁਲਨ',
    tr: 'Kullanılabilir Bakiye :',
  },
  period: {
    en: 'Period',
    pt: 'Período',
    id: 'Periode',
    vi: `Thời kỳ
Các thời kỳ`,
    ms: 'Tempoh',
    bn: 'কাল',
    pa: 'ਪੀਰੀਅਡ',
    tr: 'Dönem',
  },
  operations: {
    en: 'Operations',
    pt: 'Operações',
    id: 'Pengoperasian',
    vi: 'vận hành',
    ms: 'Operasi',
    bn: 'ক্রিয়াপ্রণালীসমূহ',
    pa: 'ਓਪਰੇਸ਼ਨ',
    tr: 'İşlemler',
  },
  totalBalance: {
    en: 'Total balance',
    pt: 'saldo total',
    id: 'Total Saldo',
    vi: 'Tổng số dư',
    ms: 'Jumlah baki',
    bn: 'মোট ব্যালেন্স',
    pa: 'ਕੁੱਲ ਬਕਾਇਆ',
    tr: 'Toplam bakiye',
  },
  h24: {
    en: '24h',
    pt: '24h',
    id: '24j',
    vi: '24 tiếng',
    ms: '24jam',
    bn: '-24ᱴᱟᱲᱟᱝ',
    pa: '24h',
    tr: '24saat Biçimi',
  },
  dontHaveAnyTrans: {
    en: 'You don\'t have any transactions yet',
    pt: 'Você ainda não tem nenhuma transação.',
    id: 'Anda belum memiliki transaksi',
    vi: 'Bạn chưa có giao dịch nào',
    ms: 'Anda belum mempunyai sebarang transaksi',
    bn: 'আপনার এখনও কোনও লেনদেন হয়নি',
    pa: 'ਤੁਹਾਡੇ ਕੋਲ ਅਜੇ ਤੱਕ ਕੋਈ ਟ੍ਰਾਂਜੈਕਸ਼ਨ ਨਹੀਂ ਹੈ',
    tr: 'Henüz herhangi bir işleminiz yok',
  },
  dontHaveAnyTransWithFilters: {
    en: 'You don\'t have any transactions with such filters',
    pt: 'Não tem quaisquer transações com esses filtros',
    id: 'Anda tidak memiliki transaksi dengan filter tersebut',
    vi: 'Bạn không có bất kỳ giao dịch nào với các bộ lọc như vậy',
    ms: 'Anda tidak mempunyai sebarang transaksi dengan penapis sedemikian',
    bn: 'এই ধরনের ফিল্টারের সাথে আপনার কোনও লেনদেন নেই',
    pa: 'ਤੁਹਾਡੇ ਕੋਲ ਅਜਿਹੇ ਫਿਲਟਰਾਂ ਨਾਲ ਕੋਈ ਲੈਣ-ਦੇਣ ਨਹੀਂ ਹੈ',
    tr: 'Bu tür filtrelerle herhangi bir işleminiz yok',
  },
  realizedPL: {
    en: 'Realized P&L',
    pt: 'Realizado P',
    id: 'Realisasi P',
    vi: 'P đã thực hiện',
    ms: 'Realisasi P',
    bn: 'বাস্তবায়িত P',
    pa: 'ਅਹਿਸਾਸ ਹੋਇਆ ਪੀ',
    tr: 'Gerçekleşen P',
  },
  unrealizedPL: {
    en: 'Unrealized P&L',
    pt: 'P não realizado',
    id: 'P yang belum direalisasi',
    vi: 'P chưa thực hiện',
    ms: 'P yang belum direalisasi',
    bn: 'অবাস্তব P',
    pa: 'ਅਣਅਧਿਕਾਰਤ ਪੀ',
    tr: 'Gerçekleşmemiş P',
  },
  youDontHaveAssets: {
    en: 'You don\'t have any assets yet',
    pt: 'Ainda não tem nenhum ativo',
    id: 'Anda belum memiliki aset',
    vi: 'Bạn chưa có bất kỳ tài sản nào',
    ms: 'Anda belum mempunyai sebarang aset',
    bn: 'কোন সম্পত্তি নেই',
    pa: 'ਤੁਹਾਡੇ ਕੋਲ ਅਜੇ ਕੋਈ ਸੰਪਤੀ ਨਹੀਂ ਹੈ',
    tr: 'Henüz hiç varlığınız yok',
  },
  price: {
    en: 'Price (USD)',
    pt: 'Preço (USD)',
    id: 'USD Harga',
    vi: 'GIÁ (USD)',
    ms: 'Harga (USD)',
    bn: 'প্রাইস (USD)',
    pa: 'ਕੀਮਤ (USD)',
    tr: 'Fiyat (USD)',
  },
  market: {
    en: 'Market',
    pt: 'Mercado',
    id: 'Pasar',
    vi: 'Thị trường',
    ms: 'Pasaran',
    bn: 'বাজার',
    pa: 'Meta',
    tr: 'Piyasa',
  },
  stopLimitPriority: {
    en: 'Stop-limit',
    pt: 'limitador de paragem',
    id: 'Stop - limit',
    vi: 'Lệnh dừng giới hạn',
    ms: 'Had Henti',
    bn: 'স্টপ-লিমিট',
    pa: 'ਰੋਕੋ-ਸੀਮਾ',
    tr: 'STOP-LİMİT',
  },
  stopLimit: {
    en: 'Stop-limit',
    pt: 'limitador de paragem',
    id: 'Stop - limit',
    vi: 'Lệnh dừng giới hạn',
    ms: 'Had Henti',
    bn: 'স্টপ-লিমিট',
    pa: 'ਰੋਕੋ-ਸੀਮਾ',
    tr: 'STOP-LİMİT',
  },
  limit: {
    en: 'Limit',
    pt: 'Limite',
    id: 'Batas',
    vi: 'Giới hạn',
    ms: 'Had',
    bn: 'সীমা',
    pa: 'ਲਿਮਟ',
    tr: 'Sınır',
  },
  fee: {
    en: 'Fee',
    pt: 'Honorários',
    id: 'Biaya',
    vi: 'Phí',
    ms: 'Yuran',
    bn: 'ফি',
    pa: 'ਫੀਸ',
    tr: 'Fiyat',
  },
  quantity: {
    en: 'Quantity',
    pt: 'Quantidade',
    id: 'Jumlah',
    vi: 'Số lượng',
    ms: 'Kuantiti',
    bn: 'পরিমাণ',
    pa: 'ਮਾਤਰਾ',
    tr: 'Miktar',
  },
  pair: {
    en: 'Pair',
    pt: 'Emparelhar',
    id: 'Berpasangan',
    vi: 'Ghép cặp',
    ms: 'Pasangan',
    bn: 'Pares',
    pa: 'ਪੇਅਰ',
    tr: 'İşlem Çifti',
  },
  change: {
    en: 'Change',
    pt: 'Alteração',
    id: 'Ubah',
    vi: 'Thay đổi',
    ms: 'Tukar',
    bn: 'পরিবর্তন করুন',
    pa: 'ਬਦਲਾਅ',
    tr: 'Değiştir',
  },
  total: {
    en: 'Total',
    pt: 'Total',
    id: 'Total',
    vi: 'Tổng',
    ms: 'Jumlah',
    bn: 'সর্বমোট',
    pa: 'ਕੁੱਲ',
    tr: 'Toplam',
  },
  marketTrades: {
    en: 'Market trades',
    pt: 'Negociações de mercado',
    id: 'Perdagangan pasar',
    vi: 'Giao dịch thị trường',
    ms: 'Perdagangan pasaran',
    bn: 'বাজার ব্যবসা',
    pa: 'ਮਾਰਕੀਟ ਵਪਾਰ',
    tr: 'Piyasa İşlemleri',
  },
  orderBook: {
    en: 'Order book',
    pt: 'Livro de Ordens',
    id: 'Buku pesanan',
    vi: 'Sổ lệnh',
    ms: 'Buku pesanan',
    bn: 'অর্ডার বুক',
    pa: 'ਆਰਡਰ ਕਿਤਾਬ',
    tr: 'Sipariş defteri',
  },
  unknownError: {
    en: 'Unknown Error',
    pt: 'Erro desconhecido',
    id: 'Kesalahan Tidak Dikenal',
    vi: 'Lỗi không rõ',
    ms: 'Unknown error',
    bn: 'অজানা ত্রুটি',
    pa: 'ਅਣਜਾਣ ਗਲਤੀ',
    tr: 'Bilinmeyen hata',
  },
  joinFree: {
    en: 'Join free',
    pt: 'Participar de graça',
    id: 'Daftar Gratis',
    vi: 'Tham gia miễn phí.',
    ms: 'Sertailah secara percuma',
    bn: 'বিনামূল্যে যোগ দিন',
    pa: 'ਮੁਫਤ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਵੋ',
    tr: 'Kayıt Ol',
  },
  apiName: {
    en: 'API name',
    pt: 'Nome da API',
    id: 'Nama api',
    vi: 'Tên API',
    ms: 'Nama api',
    bn: 'API এর নাম',
    pa: 'ਏਪੀਆਈ ਨਾਮ',
    tr: 'API adı',
  },
  title_key: {
    en: '{title} - {key}',
    pt: '{title} - {key}',
    id: '{title} - {key}',
    vi: '{title} - {key}',
    ms: '{title} - {key}',
    bn: '{title} - {key}',
    pa: '{title} - {key}',
    tr: '{title} - {key}',
  },
  BASIC: {
    en: 'Basic',
    pt: 'Básico',
    id: 'Basic',
    vi: 'Cơ bản',
    ms: 'Asas',
    bn: 'বেসিক',
    pa: 'ਬਾਲਟਿਕ',
    tr: 'Temel',
  },
  STANDARD: {
    en: 'Standard',
    pt: 'Padrão',
    id: 'Standard',
    vi: 'Tiêu chuẩn',
    ms: 'Piawaian',
    bn: 'আদর্শ। ',
    pa: 'ਸਟੈਂਡਰਡ',
    tr: 'Standart',
  },
  PRO: {
    en: 'Pro',
    pt: 'Profissional',
    id: 'Pro',
    vi: 'Chuyên nghiệp',
    ms: 'Pro',
    bn: 'স্বপক্ষে',
    pa: 'ਪ੍ਰੋ',
    tr: 'Profesyonel',
  },
  UNVERIFIED: {
    en: 'Unverified',
    pt: 'Não verificado',
    id: 'Tidak diverifikasi',
    vi: 'Chưa được xác minh',
    ms: 'Belum disahkan',
    bn: 'অপরিক্ষীত',
    pa: 'ਨਾ- ਪ੍ਰਮਾਣਿਤ',
    tr: 'Doğrulanmamış',
  },
  verifyNow: {
    en: 'Verify now',
    pt: 'Verificar agora',
    id: 'Verifikasi sekarang!',
    vi: 'Xác thực ngay',
    ms: '>Sahkan Sekarang<',
    bn: 'ভেরিফাই করুন',
    pa: 'ਹੁਣੇ ਤਸਦੀਕ ਕਰੋ',
    tr: 'Şimdi doğrula',
  },
  submit: {
    en: 'Submit',
    pt: 'Apresentar',
    id: 'Kirim',
    vi: 'Gửi',
    ms: 'Hantar',
    bn: 'জমা দিন',
    pa: 'ਦਰਜ਼ ਕਰੋ',
    tr: 'Gönder',
  },
  algoTrading: {
    en: 'Algo trading',
    pt: 'Algo Trading',
    id: 'Perdagangan algo',
    vi: 'Giao dịch thuật toán',
    ms: 'Perdagangan algo',
    bn: 'Algo ট্রেডিং',
    pa: 'Algo ਵਪਾਰ',
    tr: 'Algo ticareti',
  },
  spotWallet: {
    en: 'Spot',
    pt: 'À vista',
    id: 'Spot',
    vi: 'Điểm',
    ms: 'Setempat',
    bn: 'দাগ',
    pa: 'ਸਪੋਟ',
    tr: 'benek',
  },
  bonusWallet: {
    en: 'Bonus',
    pt: 'Bónus',
    id: 'Bonus',
    vi: 'Thưởng',
    ms: 'Bonus',
    bn: 'বোনাস',
    pa: 'ਬੋਨਸ',
    tr: 'Prim',
  },
  bonusProgram: {
    en: 'Bonus program',
    pt: 'Programa de bônus',
    id: 'Program bonus',
    vi: 'Chương trình tiền thưởng',
    ms: 'Program bonus',
    bn: 'বোনাস প্রোগ্রাম',
    pa: 'ਬੋਨਸ ਪ੍ਰੋਗਰਾਮ',
    tr: 'Bonus programı',
  },
  discord: {
    en: 'Discord',
    pt: 'Discord',
    id: 'Perselisihan',
    vi: 'Bất hòa',
    ms: 'Discord',
    bn: 'ডিসকর্ড',
    pa: 'Discord',
    tr: 'Discord',
  },
  twitter: {
    en: 'X (Twitter)',
    pt: 'X (Twitter)',
    id: 'X (Twitter)',
    vi: 'X (Twitter)',
    ms: 'X (Twitter)',
    bn: 'X (টুইটার)',
    pa: 'X (ਟਵਿੱਟਰ)',
    tr: 'X (Twitter)',
  },
  telegram: {
    en: 'Telegram',
    pt: 'Telegram',
    id: 'Telegram',
    vi: 'Telegram',
    ms: 'Telegrafi',
    bn: 'টেলিগ্রাফি',
    pa: 'ਟੈਲੀਗ੍ਰਾਮ',
    tr: 'Telegram',
  },
  facebook: {
    en: 'Facebook',
    pt: 'Facebook',
    id: 'Facebook',
    vi: 'Facebook',
    ms: 'Facebook',
    bn: 'ফেসবুক',
    pa: 'Facebook',
    tr: 'Facebook',
  },
  linkedIn: {
    en: 'LinkedIn',
    pt: 'LinkedIn',
    id: 'LinkedIn',
    vi: 'LinkedIn',
    ms: 'LinkedIn',
    bn: 'লিঙ্কডইন',
    pa: 'LinkedIn',
    tr: '&#10;LinkedIn ',
  },
  instagram: {
    en: 'Instagram',
    pt: 'Instagram',
    id: 'Instagram',
    vi: 'Instagram',
    ms: 'Instagram',
    bn: 'ইনস্টাগ্রাম',
    pa: 'Instagram',
    tr: 'Instagram',
  },
  medium: {
    en: 'Medium',
    pt: 'Médio',
    id: 'Sedang',
    vi: 'Hạng trung',
    ms: 'Medium',
    bn: 'মাঝারি',
    pa: 'ਮੱਧਮ',
    tr: 'Orta',
  },
  createAccount: {
    en: 'Create account',
    pt: 'Criar conta',
    id: 'Buat akun',
    vi: 'Tạo tài khoản',
    ms: 'Cipta akaun',
    bn: 'অ্যাকাউন্ট তৈরি করুন',
    pa: 'ਹੁਣੇ ਅਕਾਊਂਟ ਬਣਾਓ',
    tr: 'Hesap oluştur',
  },
  changeEmail: {
    en: 'Change email',
    pt: 'Alterar e-mail',
    id: 'Ganti email',
    vi: 'Thay đổi email',
    ms: 'Tukar e-mel',
    bn: 'ইমেইল পরিবর্তন করুন',
    pa: 'ਈਮੇਲ ਬਦਲੋ',
    tr: 'E-posta değiştir',
  },
}
