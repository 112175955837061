import { Container } from '../Container/styled';

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import sendTokensImg from 'src/assets/img/send-tokens.webp';
import swapTokensImg from 'src/assets/img/swap-tokens.webp';
import { ImageStyled, SlideTitle, SwapsWrapper } from './styled';

export const Swaps = () => {
  return (
    <Container style={{ marginBottom: '12.8vw' }}>
      <SwapsWrapper>
        <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}>
          <SwiperSlide>
            <SlideTitle>
              <span style={{ color: 'var(--color-primary-5)' }}>swap</span>{' '}
              popular tokens
            </SlideTitle>
            <ImageStyled src={swapTokensImg} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideTitle>
              <span style={{ color: 'var(--color-primary-5)' }}>send</span>{' '}
              popular tokens
            </SlideTitle>
            <ImageStyled src={sendTokensImg} />
          </SwiperSlide>
        </Swiper>
      </SwapsWrapper>
    </Container>
  );
};
