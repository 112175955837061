import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 4.267vw 0;
`;

export const TitleStyled = styled.p`
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 5.12vw;
  letter-spacing: 0.154vw;
`;

export const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${props => (props.$menuOpen ? '0' : '100%')};
  right: 0;
  bottom: 0;
  background-color: var(--color-neutral-12);
  transition: all 0.3s ease;
  padding: 5.333vw 5.333vw 17.333vw;
  overflow: auto;

  height: 100%;
  z-index: 9999;
`;

export const ButtonStyled = styled.a`
  display: inline-block;
  text-decoration: none;
  width: 100%;
  color: var(--color-neutral-1);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 5.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 7.467vw;
  letter-spacing: 0.053vw;
  border-radius: 2.933vw;
  padding: 4.5vw 0;
  -webkit-user-select: none;
  user-select: none;
  ${({ $primary }) =>
    $primary &&
    css`
      color: var(--color-neutral-11);
      background: var(--color-primary-5);
    `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 13.333vw;
  gap: 5.333vw;
  flex: 1;
`;

export const TextStyled = styled.p`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.053vw;
  margin-top: 4.267vw;
  padding-bottom: 10.667vw;
`;

export const StoresWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 5.333vw;
  margin: 3.733vw 0 10.667vw;
`;
