import styled from 'styled-components';

export const ContentTextsStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.778vw;
  align-self: stretch;
  flex-wrap: wrap;
`;
export const ContentTitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  text-align: center;
  font-size: 2.778vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 1.667vw;
`;
export const ContentSpecialTextStyled = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #aade16;
`;
export const ContentSubTitleStyled = styled.p`
  color: #8f96a3;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.01vw;
  margin-bottom: 2.5vw;
`;

export const ContentBlocksStyled = styled.div`
  display: flex;
  gap: 1.667vw;
`;
export const ContentBlockStyled = styled.div`
  width: 100%;
  padding: 1.944vw;
  display: flex;
  flex-direction: column;
  border-radius: 1.111vw;
  border: 0.069vw solid #525a67;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(64px);
`;

export const ContentBlockTitleStyled = styled.p`
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 1.389vw;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.012vw;
  margin-top: 1.111vw;
`;

export const ContentBlockSubTitleStyled = styled.p`
  color: #979eaa;
  font-family: Inter, sans-serif;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.008vw;
  margin-top: 0.833vw;
`;
