import styled from 'styled-components';

export const DataItemPoint = styled.p`
  color: #c6cfd8;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-size: 5.333vw;
  @media (min-width: 769px) {
    font-size: 1.389vw;
  }
`;
export const Link = styled.a`
  color: var(--color-neutral-1);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-size: 5.333vw;
  cursor: pointer;
  @media (min-width: 769px) {
    font-size: 1.389vw;
  }
`;

export const TitleStyled = styled.p`
  color: #fff;
  font-size: 10.667vw;
  letter-spacing: 0.107vw;
  font-family: Inter, sans-serif;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 10.667vw;
  @media (min-width: 769px) {
    font-size: 3.333vw;
    letter-spacing: 0.028vw;
    margin-top: 4.444vw;
  }
`;
export const UpdateInfoStyled = styled.p`
  color: #aade16;
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  margin-top: 6.4vw;
  letter-spacing: 0.085vw;
  line-height: 8.533vw;
  @media (min-width: 769px) {
    font-size: 1.667vw;
    line-height: 2.222vw;
    letter-spacing: 0.022vw;
    margin-top: 1.389vw;
  }
`;
export const DescriptionStyled = styled.p`
  color: #c6cfd8;
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  line-height: 150%;
  font-style: normal;
  margin-top: 6.4vw;
  @media (min-width: 769px) {
    font-size: 1.389vw;
    margin-top: 1.389vw;
  }
`;
export const DataWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12.8vw;
  margin-top: 12.8vw;
  @media (min-width: 769px) {
    gap: 3.056vw;
    margin-top: 3.056vw;
  }
`;
export const DataItemStyled = styled.div``;
export const DataItemPoints = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4.267vw;
  gap: 4.267vw;
  @media (min-width: 769px) {
    gap: 0.278vw;
    margin-top: 0.833vw;
  }
`;

export const DataItemTitle = styled.p`
  color: #fff;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 7.467vw;
  margin-top: 10vw;
  @media (min-width: 769px) {
    font-size: 2.222vw;
    margin-top: 3.056vw;
  }
`;

export const DataItemHeader = styled.p`
  margin-top: 8.8vw;
  color: #fff;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 6.467vw;
  @media (min-width: 769px) {
    font-size: 1.8vw;
    margin-top: 2.056vw;
  }
`;

export const BottomDescription = styled.p`
  color: #c6cfd8;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-size: 5.333vw;
  margin-top: 12.8vw;
  @media (min-width: 769px) {
    font-size: 1.389vw;
    letter-spacing: 0.022vw;
    margin-top: 3.056vw;
  }
`;
export const HaqabiWalletTeam = styled.p`
  color: #aade16;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-bottom: 1px solid #29313e;
  font-size: 5.5vw;
  margin-top: 5.5vw;
  padding-bottom: 17.067vw;
  letter-spacing: 0.085vw;
  @media (min-width: 769px) {
    font-size: 1.389vw;
    letter-spacing: 0.022vw;
    margin-top: 1.667vw;
    padding-bottom: 4.514vw;
  }
`;

export const ScrollToTop = styled.div`
  padding: 2.4vw;
  border-radius: 50%;
  border: 1px solid #434d59;
  background: #29313e;
  box-shadow:
    0px 0px 128px -2.133px #161a1f,
    0px 0px 60.27px -2.133px #161a1f;
  position: fixed;
  bottom: 8.533vw;
  right: 8.533vw;
  z-index: 100;
`;

export const ListElement = styled.li`
  &::before {
    content: '';
    display: inline-block;
    background-color: white;
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    margin-right: 2vw;
    @media (min-width: 769px) {
      width: 0.833vw;
      height: 0.833vw;
      margin-right: 1vw;
    }
  }
`;
