import { ReactComponent as CancelIcon } from 'src/assets/icons/cancel.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check-circle-broken.svg';
import { ReactComponent as ExodusIcon } from 'src/assets/icons/exodus.svg';
import { ReactComponent as HaqabiIcon } from 'src/assets/icons/haqabi.svg';
import { ReactComponent as MetamaskIcon } from 'src/assets/icons/metamask.svg';
import { ReactComponent as TrustWalletIcon } from 'src/assets/icons/trust-wallet.svg';
import { useDeviceType } from 'src/hooks/useDeviceType';
import {
  IconTitleStyled,
  IconWrapperStyled,
  TableDescriptionStyled,
  TableStyled,
  TableSubtitleStyled,
  TableTitleStyled,
  TdStyled,
  TitleStyled,
  TrStyled,
} from './styled';
import { Container } from 'src/components/Container/styled';
const iconSize = '2.778vw';

const mobileData = [
  {
    rowName: <TableTitleStyled>Wallet</TableTitleStyled>,
    icon1: (
      <div>
        <HaqabiIcon width={'5.067vw'} height={'5.6vw'} />
        <IconTitleStyled>HAQABI</IconTitleStyled>
      </div>
    ),
    icon2: <TrustWalletIcon width={'5.067vw'} height={'5.6vw'} />,
    icon3: <MetamaskIcon width={'5.067vw'} height={'5.6vw'} />,
    icon4: <ExodusIcon width={'5.067vw'} height={'5.6vw'} />,
  },
  {
    rowName: <TableTitleStyled>Global Market Coverage</TableTitleStyled>,
    icon1: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
    icon2: <CancelIcon width={'6.4vw'} height="6.4vw" />,
    icon3: <CancelIcon width={'6.4vw'} height="6.4vw" />,
    icon4: <CancelIcon width={'6.4vw'} height="6.4vw" />,
  },
  {
    rowName: <TableTitleStyled>Decentralized</TableTitleStyled>,
    icon1: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
    icon2: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
    icon3: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
    icon4: <CancelIcon width={'6.4vw'} height="6.4vw" />,
  },
  {
    rowName: (
      <div>
        <TableTitleStyled>Portfolio Management</TableTitleStyled>
        <TableDescriptionStyled>Coming soon</TableDescriptionStyled>
      </div>
    ),
    icon1: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
    icon2: <CancelIcon width={'6.4vw'} height="6.4vw" />,
    icon3: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
    icon4: <CancelIcon width={'6.4vw'} height="6.4vw" />,
  },
  {
    rowName: (
      <div>
        <TableTitleStyled>Cross-chain Staking</TableTitleStyled>
        <TableDescriptionStyled>Q3 2024</TableDescriptionStyled>
      </div>
    ),
    icon1: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
    icon2: <CancelIcon width={'6.4vw'} height="6.4vw" />,
    icon3: <CancelIcon width={'6.4vw'} height="6.4vw" />,
    icon4: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
  },

  {
    rowName: (
      <div>
        <TableTitleStyled>Comission Optimisation</TableTitleStyled>
        <TableDescriptionStyled>Q4 2025</TableDescriptionStyled>
      </div>
    ),
    icon1: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
    icon2: <CancelIcon width={'6.4vw'} height="6.4vw" />,
    icon3: <CancelIcon width={'6.4vw'} height="6.4vw" />,
    icon4: (
      <CheckIcon
        width="6.4vw"
        height="6.4vw"
        style={{ color: 'transparent' }}
      />
    ),
  },
];

const desktopData = [
  {
    rowName: <TableTitleStyled>Wallet</TableTitleStyled>,
    icon1: (
      <IconWrapperStyled>
        <HaqabiIcon width={'2.292vw'} height={'2.569vw'} />
        <IconTitleStyled>HAQABI</IconTitleStyled>
      </IconWrapperStyled>
    ),
    icon2: (
      <IconWrapperStyled>
        <TrustWalletIcon width={'2.083vw'} height={'2.083vw'} />
        <IconTitleStyled>Trust Wallet</IconTitleStyled>
      </IconWrapperStyled>
    ),
    icon3: (
      <IconWrapperStyled>
        <MetamaskIcon width={'2.083vw'} height={'2.083vw'} />
        <IconTitleStyled>Metamask</IconTitleStyled>
      </IconWrapperStyled>
    ),
    icon4: (
      <IconWrapperStyled>
        <ExodusIcon width={'2.083vw'} height={'2.083vw'} />
        <IconTitleStyled>Exodus</IconTitleStyled>
      </IconWrapperStyled>
    ),
  },
  {
    rowName: (
      <div>
        <TableTitleStyled>Global Market Coverage</TableTitleStyled>
        <TableDescriptionStyled>
          Both Islamic & traditional banking
        </TableDescriptionStyled>
      </div>
    ),
    icon1: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
    icon2: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
    icon3: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
    icon4: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
  },
  {
    rowName: (
      <div>
        <TableTitleStyled>Decentralized</TableTitleStyled>
        <TableDescriptionStyled>
          Complete user control over assets
        </TableDescriptionStyled>
      </div>
    ),
    icon1: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
    icon2: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
    icon3: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
    icon4: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
  },
  {
    rowName: (
      <div>
        <TableTitleStyled>Portfolio Management</TableTitleStyled>
        <TableDescriptionStyled $isGreen>Coming Soon</TableDescriptionStyled>
      </div>
    ),
    icon1: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
    icon2: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
    icon3: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
    icon4: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
  },
  {
    rowName: (
      <div>
        <TableTitleStyled>
          Earn Crypto <TableSubtitleStyled>Q3 2024</TableSubtitleStyled>
        </TableTitleStyled>
        <TableDescriptionStyled>Cross-chain staking</TableDescriptionStyled>
      </div>
    ),
    icon1: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
    icon2: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
    icon3: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
    icon4: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
  },

  {
    rowName: (
      <div>
        <TableTitleStyled>
          Comission Optimisation
          <TableSubtitleStyled> Q4 2025</TableSubtitleStyled>
        </TableTitleStyled>
        <TableDescriptionStyled>
          Due to cross-chain dex aggregator
        </TableDescriptionStyled>
      </div>
    ),
    icon1: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
    icon2: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
    icon3: <CancelIcon style={{ width: '3.333vw', height: '3.333vw' }} />,
    icon4: (
      <CheckIcon
        style={{ width: iconSize, height: iconSize, color: 'transparent' }}
      />
    ),
  },
];

export const Difference = () => {
  const { isMobile } = useDeviceType();
  return (
    <Container id="Comparison">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <TitleStyled>What is the difference?</TitleStyled>
        <TableStyled>
          <tbody>
            {(isMobile ? mobileData : desktopData).map((row, idx) => (
              <TrStyled key={idx}>
                <TdStyled>{row.rowName}</TdStyled>
                <TdStyled>{row.icon1}</TdStyled>
                <TdStyled>{row.icon2}</TdStyled>
                <TdStyled>{row.icon3}</TdStyled>
                <TdStyled>{row.icon4}</TdStyled>
              </TrStyled>
            ))}
          </tbody>
        </TableStyled>
      </div>
    </Container>
  );
};
