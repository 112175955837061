import React, { useEffect, useState } from 'react';
import {
  BottomTextStyled,
  ContentInfoStyled,
  ContentSocialsStyled,
  ContentSocialsTextStyled,
  ContentStyled,
  InfoBlockStyled,
  InfoBlockTextStyled,
  InfoBlockTitleStyled,
  SocialsBlockStyled,
  SocialsIconBlockStyled,
} from './styled';
import { ReactComponent as HaqabiIcon } from 'src/assets/icons/haqabi.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/icons/twitter.svg';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram.svg';
import { ReactComponent as DiscordIcon } from 'src/assets/icons/discord.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/icons/linkedIn.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/icons/facebook.svg';
import { ReactComponent as RedditIcon } from 'src/assets/icons/reddit.svg';
import { Container } from 'src/components/Container/styled';
import { useNavigate } from 'react-router-dom';

export const FooterDesktop = ({ isMain }) => {
  const [elementId, setElementId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (elementId) {
      navigate('/', { state: { elementId } });
    }

    if (!isMain) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    return () => setElementId('');
  }, [elementId, navigate]);
  return (
    <Container>
      <ContentStyled>
        <ContentSocialsStyled>
          <SocialsIconBlockStyled onClick={() => window.scrollTo(0, 0)}>
            <HaqabiIcon width={'2.083vw'} height={'2.361vw'} />
            <ContentSocialsTextStyled>HAQABI</ContentSocialsTextStyled>
          </SocialsIconBlockStyled>
          <InfoBlockStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('Features')}>
              Features
            </InfoBlockTitleStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('Comparison')}>
              Comparison
            </InfoBlockTitleStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('Timeline')}>
              Timeline
            </InfoBlockTitleStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('AboutUs')}>
              About us
            </InfoBlockTitleStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('Bonus')}>
              Get Bonus
            </InfoBlockTitleStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('Education')}>
              Education
            </InfoBlockTitleStyled>
            {/* <InfoBlockTitleStyled onClick={() => setElementId('Documentation')}>
              Documentation
            </InfoBlockTitleStyled> */}
          </InfoBlockStyled>
        </ContentSocialsStyled>
        <ContentInfoStyled>
          <SocialsBlockStyled>
            <a href="https://twitter.com/HaqabiHQ">
              <TwitterIcon
                width={'2.778vw'}
                height={'2.778vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </a>
            <a href="https://t.me/haqabi">
              <TelegramIcon
                width={'2.778vw'}
                height={'2.778vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </a>
            <a href="https://discord.gg/kXeefuKPmQ">
              <DiscordIcon
                width={'2.778vw'}
                height={'2.778vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </a>
            <a href="https://www.linkedin.com/company/haqabi/">
              <LinkedInIcon
                width={'2.778vw'}
                height={'2.778vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </a>
            <a href="https://www.facebook.com/haqabi.wallet">
              <FacebookIcon
                width={'2.778vw'}
                height={'2.778vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </a>
            <a href="https://www.reddit.com/r/haqabi/">
              <RedditIcon
                width={'2.778vw'}
                height={'2.778vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </a>
          </SocialsBlockStyled>
          <InfoBlockTextStyled>
            Contact us{' '}
            <span style={{ color: '#AADE16' }}>support@haqabi.com</span>
          </InfoBlockTextStyled>
        </ContentInfoStyled>
      </ContentStyled>
      <BottomTextStyled>
        Copyright © 2024. All rights reserved.
      </BottomTextStyled>
    </Container>
  );
};
