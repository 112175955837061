const authToken = 'fcc0efd765e191ebd1a2a934609a0833090f1d33';
export const PAGE_SIZE = 6;

export const fetchData = async (url, params) => {
  const baseUrl = 'https://api.buttercms.com/v2/';

  let queryParams = `auth_token=${authToken}`;
  if (params) {
    for (const key in params) {
      if (params[key]) {
        queryParams += `&${key}=${encodeURIComponent(params[key])}`;
      }
    }
  }

  try {
    const fullUrl = `${baseUrl}${url}/?${queryParams}`;
    const response = await fetch(fullUrl, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
