import React from 'react';
import cx from 'classnames';

import s from './index.module.scss';
import { Text } from 'src/components/CMS/components';
import { useBreakpoint } from 'src/hooks/useBreakpoint';

export const Article = props => {
  const { url, image, title, description } = props;
  const { lg } = useBreakpoint();

  return (
    <a
      href={url}
      className={cx(
        'flex flex-col overflow-hidden ',
        lg ? 'h-full' : 'h-auto',
      )}>
      {!!image && (
        <div
          className={cx('w-full inline-block', s.image)}
          style={{ backgroundImage: `url(${image})` }}
        />
      )}
      <div className="flex flex-col pt-20">
        <Text size="b16" message={title} />
        <Text
          className="mt-8"
          size="r14"
          color="neutral-4"
          message={description}
        />
      </div>
    </a>
  );
};
