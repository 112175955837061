import styled, { css } from 'styled-components';

export const TitleStyled = styled.p`
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 6.4vw;
`;

export const CardItem = styled.div`
  display: flex;
  padding: 4.267vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.267vw;
  border-radius: 3.2vw;
  border: 0.267vw solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(2.533vw);
  height: 40vw;
`;

export const PeriodStyled = styled.span`
  color: #171d25;
  font-family: Inter, sans-serif;
  font-size: 4.267vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.064vw;
  padding: 0 2.133vw;
  border-radius: 2.133vw;
  background: #c2ee4e;
`;

export const YearStyled = styled.span`
  color: #c2ee4e;
  font-family: Inter, sans-serif;
  font-size: 4.267vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.064vw;
`;

const primaryItemStyled = status => {
  if (status !== 'primary') {
    return css`
      display: inline-block;
      width: 2.133vw;
      height: 2.133vw;
      border: 0.267vw solid #c2ee4e;
      border-radius: 50%;
      margin-right: 3.2vw;
    `;
  }
};

export const ItemStyled = styled.li`
  display: flex;
  color: ${props => (props.$primary ? '#c2ee4e' : '#eaedee')};
  font-family: Inter, sans-serif;
  font-size: 3.7vw;
  font-style: normal;
  font-weight: ${({ $primary }) => (!$primary ? '400' : '600')};
  line-height: normal;
  letter-spacing: -0.053vw;
  align-items: center;
  &::before {
    content: '';
    ${({ $primary }) => (!$primary ? primaryItemStyled($primary) : '')}
  }
`;

export const ProjectTimeLineWrapper = styled.div`
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 80%;
  }

  .swiper-pagination {
    position: relative;
    bottom: 0;
    top: 0;
    margin-top: 4.267vw;
  }

  .swiper-pagination-bullet {
    width: 2.133vw;
    height: 2.133vw;
    border: 0.267vw solid #333b48;
    border-radius: 50%;
    background-color: #333b48;
  }

  .swiper-pagination-bullet-active {
    width: 2.133vw;
    height: 2.133vw;
    background-color: #c2ee4e;
  }
`;
