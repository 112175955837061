export const PATH = {
  EDUCATION: 'https://api.buttercms.com/v2/',
};

export const DISCORD_URL = 'https://discord.gg/haqqex';
export const TWITTER_URL = 'https://twitter.com/haqqexhq';
export const TELEGRAM_URL = 'https://t.me/haqqexchange';
export const FACEBOOK_URL = 'https://www.facebook.com/haqqex/';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/haqqex/';
export const INSTAGRAM_URL = 'https://www.instagram.com/haqqex/';
export const MEDIUM_URL = 'https://medium.com/@haqqex';
