import React, { useMemo } from 'react';
import cx from 'classnames';

import s from './index.module.scss';

const Image = (props, ref) => {
  const { className, src, color, size, fullWidth } = props;

  const style = useMemo(() => {
    const isNum = typeof size === 'number';

    const width = fullWidth
      ? '100%'
      : isNum
        ? `${size / 10}rem`
        : `${size.w / 10}rem`;
    const height = isNum ? `${size / 10}rem` : `${size.h / 10}rem`;

    const dimensions = {
      width,
      height,
      minWidth: width,
    };

    const imageUrl = `url(${src})`;

    if (color) {
      return {
        ...dimensions,
        maskImage: imageUrl,
        WebkitMaskImage: imageUrl,
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center center',
        WebkitMaskSize: 'contain',
      };
    }

    return {
      ...dimensions,
      backgroundImage: imageUrl,
    };
  }, [src, size, color]);

  if (!src) {
    console.error('You must pass the image src');
    return null;
  }

  return (
    <div
      ref={ref}
      className={cx(className, s.image, 'inline-block', {
        [`bg-${color}`]: Boolean(color),
      })}
      style={style}
    />
  );
};

export default React.memo(React.forwardRef(Image));
