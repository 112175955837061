import styled from 'styled-components';

export const TitleStyled = styled.p`
  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 6.4vw;

  @media (min-width: 769px) {
    font-size: 2.778vw;
    font-style: normal;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 3.333vw;
  }
`;

export const TableTitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 3.733vw;
  letter-spacing: 0.02vw;

  @media (min-width: 769px) {
    font-size: 1.25vw;
    line-height: 1.389vw;
  }
`;

export const TableDescriptionStyled = styled.p`
  color: #c4f538;
  font-family: Inter, sans-serif;
  font-size: 2.133vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02vw;
  margin-top: 1.067vw;

  @media (min-width: 769px) {
    color: ${({ $isGreen }) => ($isGreen ? '#c4f538' : '#EAEDEE')};
    font-family: Inter, sans-serif;
    font-size: 1.042vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.01vw;
    text-transform: initial;
    opacity: ${({ $isGreen }) => ($isGreen ? '1' : '0.6')};
    margin-top: 0.417vw;
  }
`;

export const TableSubtitleStyled = styled.span`
  color: #c4f538;

  font-family: Inter, sans-serif;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.04vw;
  margin-left: 0.278vw;
`;

export const IconWrapperStyled = styled.div`
  display: flex;
  gap: 0.556vw;
  align-items: center;
`;

export const TdStyled = styled.td`
  display: flex;
  width: 20%;
  padding: 1.389vw 0;
  align-items: center;
  &:not(:first-child) {
    justify-content: center;
  }
  &:first-child {
    width: 30%;
  }
  &:nth-child(2) {
    width: 30%;
  }

  height: 14.933vw;
  @media (min-width: 769px) {
    &:first-child {
      width: 30%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    height: auto;
  }
`;

export const TrStyled = styled.tr`
  display: flex;
  justify-content: space-between;
  ${TdStyled}:not(:first-child) {
    text-align: center;
  }

  ${TdStyled}:not(:nth-child(2)) {
    border-bottom: 0.099vw solid #333b48;
  }

  ${TdStyled}:nth-child(2) {
    background: rgba(255, 255, 255, 0.05);
    border-left: 0.139vw solid rgba(170, 222, 22, 0.5);
    border-right: 0.139vw solid rgba(170, 222, 22, 0.5);
  }

  &:first-child {
    ${TdStyled}:nth-child(2) {
      border-top: 0.139vw solid rgba(170, 222, 22, 0.5);
      border-left: 0.139vw solid rgba(170, 222, 22, 0.5);
      border-right: 0.139vw solid rgba(170, 222, 22, 0.5);
      border-radius: 1.111vw 1.111vw 0 0;
    }
  }

  &:last-child {
    ${TdStyled}:nth-child(2) {
      border-bottom: 0.139vw solid rgba(170, 222, 22, 0.5);
      border-left: 0.139vw solid rgba(170, 222, 22, 0.5);
      border-right: 0.139vw solid rgba(170, 222, 22, 0.5);
      border-radius: 0 0 1.111vw 1.111vw;
    }
  }
  &:first-child {
    ${TableTitleStyled} {
      @media (min-width: 769px) {
        color: #c4f538;
        font-family: Inter, sans-serif;
        font-size: 1.25vw;
        font-style: normal;
        font-weight: 500;
        line-height: 2.5vw;
        letter-spacing: 0.014vw;
      }
    }
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  ${TrStyled}:last-child {
    ${TdStyled}:not(:nth-child(2)) {
      border-bottom: none;
    }
    ${TdStyled}:nth-child(2) {
      padding-bottom: 1.944vw;
    }
  }
  ${TrStyled}:first-child {
    ${TdStyled}:nth-child(2) {
      padding-top: 1.944vw;
    }
  }
`;

export const IconTitleStyled = styled.p`
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 2.667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-top: 1.067vw;
  @media (min-width: 769px) {
    margin-top: 0;
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
