import styled from 'styled-components';

export const TopBlockStyled = styled.div`
  padding: 16vw 0;
  display: flex;
  flex-direction: column;
`;
export const IconBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const IconsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10.667vw 0;
`;
export const TopTitleStyled = styled.span`
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 8.533vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.256vw;
`;
export const SpecialTitleStyled = styled(TopTitleStyled)`
  color: #aade16;
  font-family: Poppins, sans-serif;
  font-size: 13.867vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.256vw;
`;
export const MiddleBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MiddleTextBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.667vw;
`;

export const MiddleTitleStyled = styled.a`
  color: ${({ $primary }) => ($primary ? '#171d25' : '#eaedee')};
  background: ${({ $primary }) => ($primary ? '#aade16' : 'none')};
  text-align: center;
  font-family: Inter, sans-serif;
  display: flex;
  padding: 4.267vw 0;
  justify-content: center;
  font-size: 4.533vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.053vw;
  border: none;
  border-radius: 2.133vw;
  user-select: none;
  text-decoration: none;
`;

export const BottomStyled = styled.div`
  margin-top: 6.4vw;
  display: flex;
  flex-direction: column;

  gap: 6.24vw;
`;
export const BottomTextStyled = styled.span`
  color: #6a7484;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
`;

export const LinkStyled = styled.a``;

export const StoresWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 12.8vw;
`;
