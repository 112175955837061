import React from 'react';
import { ReactComponent as IslmIcon } from 'src/assets/icons/islm.svg';
import { ReactComponent as RecoveryIcon } from 'src/assets/icons/recovery.svg';
import { ReactComponent as SecureIcon } from 'src/assets/icons/secure.svg';
import { ReactComponent as ChainIcon } from 'src/assets/icons/chain.svg';
import { ReactComponent as CommissionIcon } from 'src/assets/icons/commission.svg';
import { ReactComponent as InterfaceIcon } from 'src/assets/icons/interface.svg';

export const featureData = [
  {
    icon: <RecoveryIcon width={'7.467vw'} height={'7.467vw'} />,
    title: 'Seedless recovery',
    description: (
      <div>
        <p>
          You can recover your wallet without memorizing a 12-word secret
          phrase.
        </p>
        <p style={{ marginTop: '2.133vw' }}>
          Even if you lose your phone, smart algorithms will help you restore
          access.
        </p>
      </div>
    ),
  },

  {
    icon: <IslmIcon width={'7.467vw'} height={'7.467vw'} />,
    title: 'Works with ISLM',
    description:
      'Buy, store and swap Islamic Coin tokens ($ISLM) and other popular tokens',
  },

  {
    icon: <InterfaceIcon width={'7.467vw'} height={'7.467vw'} />,
    title: 'Non-custodial wallet',
    description: (
      <p>
        Only you have access to your keys and assets. <br /> Your keys – your
        crypto!
      </p>
    ),
  },
  {
    icon: <ChainIcon width={'7.467vw'} height={'7.467vw'} />,
    title: 'Cross-chain staking',
    description: 'Invest in reliable crypto-assets and earn 2-10% per annum',
  },
  {
    icon: <CommissionIcon width={'7.467vw'} height={'7.467vw'} />,
    title: 'Optimized commission',
    description:
      'Save money with built-in tools to minimize commissions for transfers automatically',
  },
];
