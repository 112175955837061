import styled from 'styled-components';

export const ContentTextStyled = styled.p`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 8.533vw;
  font-style: normal;
  font-weight: 600;
  line-height: 11.733vw;
  text-transform: uppercase;
`;
export const ContentBoxBlockStyled = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 2.133vw;
  border: 0.121vw solid #525a67;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(7.733vw);
  padding: 5.333vw 4.267vw;
  &:not(:first-child) {
    margin-top: 4.267vw;
  }
  &:first-child {
    margin-top: 6.4vw;
  }
`;
export const ContentBlockTextStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 4.267vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.053vw;
  margin-left: 3.2vw;
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  line-height: 130%;
`;
export const SpecialTextStyled = styled.span`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.053vw;
`;
export const ContentWrapper = styled.div`
  margin-top: 17.6vw;
`;
