import styled from 'styled-components';

export const ContentStyled = styled.div`
  margin-top: 4.444vw;
`;
export const ContentSocialsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SocialsIconBlockStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const ContentSocialsTextStyled = styled.span`
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 1.667vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0.833vw;
  cursor: pointer;
`;

export const SocialsBlockStyled = styled.div`
  display: flex;
  gap: 0.833vw;
`;
export const ContentInfoStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.333vw;
`;
export const InfoBlockStyled = styled.div`
  display: flex;
  gap: 2.5vw;
  font-size: 3.889vw;
`;
export const InfoBlockTitleStyled = styled.a`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.458vw; /* 105% */
  cursor: pointer;
  text-decoration: none;
`;
export const InfoBlockTextStyled = styled.span`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.458vw;
  letter-spacing: 0.01vw;
`;

export const BottomTextStyled = styled.span`
  color: #6a7484;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.111vw;
  letter-spacing: 0.011vw;
  display: flex;
  justify-content: center;
  margin-top: 3.333vw;
`;
