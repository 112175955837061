import styled from 'styled-components';

// Main Wrapper
export const MainWrapper = styled.div`
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: 768px) {
    padding: 0 20px;
    gap: 32px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;

// Button Title Container
export const ButtonTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  margin-bottom: 80px;
  margin-top: 150px;

  @media (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;


// Title Container
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Main Text
export const MainTextStyled = styled.p`
  color: ${(props) => props.color || '#EAEDEE'};
  font-family: Inter, sans-serif;
  font-size: 5vw;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 8vw;
    line-height: normal;
  }
`;

export const MainSubTextStyled = styled.p`
  color: ${(props) => props.color || '#C6CFD8CC'};
  font-family: Inter, sans-serif;
  font-size: 2vw;
  font-weight: 400;
  line-height: 64px;
  text-align: center;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 8vw;
    line-height: normal;
  }
`;

// Join Button
export const JoinButton = styled.button`
  padding: 14px 0;
  width: 100%;
  max-width: 352px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.bg || '#aade1670'};
  color: ${(props) => props.bg && '#fff' || '#171D25'};
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  background-color: ${(props) => props.bg || '#C4F538'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 768px) {
    font-size: 16px;
    max-width: 100%;
  }
`;

// Instruction Title
export const InstructionTitle = styled.p`
  font-size: 20px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 16px;
    max-width: 100%;
    text-align: center;
  }
`;

// Instruction Box
export const InstructionBox = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  align-items: center;
  border: 1px solid #525a67;
  margin-bottom: 24px;
  position: relative;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-position: right bottom;

  @media (max-width: 768px) {
    background-image: none
  }

`;

// Instruction Number
export const InstructionNumber = styled.div`
  width: 100%;
  max-width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 600;
  color: #aade16;
`;

// Instruction Text
export const InstructionText = styled.div`
  font-size: 18px;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

// Referral Form
export const ReferralForm = styled.div`
  padding: 52px;
  border-radius: 12px;
  border: 1px solid #525a67;
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-position: right bottom;
  min-height: 380px;

  @media (max-width: 768px) {
    padding: 24px;
    background-image: none
  }
`;

// Form Title
export const FormTitle = styled.p`
  font-size: 36px;
  margin: 0 0 16px 0;
`;

// Form Subtitle
export const FormSubtitle = styled.p`
  font-size: 15px;
  margin: 0;
`;

// Form Input
export const FormInput = styled.input`
  max-width: 705px;
  width: 100%;
  border-radius: 9px;
  background-color: transparent;
  border: 1px solid #49505b;
  outline: none;
  color: #fff;
  padding: 17px 12px;
  font-size: 18px;
  appearance: none;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 14px 12px;
  }
`;

// Info Grid
export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// Info Section
export const InfoSection = styled.div`
  padding: 26px;
  border-radius: 12px;
  border: 1px solid #525a67;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 768px) {
    padding: 20px;
    gap: 14px;
  }
`;

// Info Header
export const InfoHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

// Info Title
export const InfoTitle = styled.p`
  color: #aade16;
  font-size: 18px;
  margin: 0;
`;

// Info Text
export const InfoText = styled.p`
  font-size: 16px;
  line-height: 30px;
  margin: 0;
`;

// Rank Table
export const RankTable = styled.div`
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #525a67;
`;

// Table Header
export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 9px 24px;
  margin-bottom: 16px;

  p {
    font-size: 18px;
    margin: 0;
  }
`;

// Table Row
export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 24px;
  border-bottom: 1px solid #1f2630;

  p {
    font-size: 18px;
    margin: 0;
  }
`;

// Show Button
export const ShowButton = styled.div`
  padding: 20px;
  text-align: center;
  cursor: pointer;
  color: #656565;
  text-decoration: underline;
  font-size: 16px;
`;


// Discrd Link 

export const DiscordLink = styled.a`
  color: #AADE16;
  text-decoration: underline;
  cursor: pointer;
`;



export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); 
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const ModalContent = styled.div`
  background-color: #1f2630;
  border-radius: 12px;
  padding: 50px;
  width: 100%;
  max-width: 754px;
  /* height: 100%;
  max-height: 415px; */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 768px) {
    padding: 50px 20px;
    gap: 28px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: #aaa;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

export const ModalTextTitle = styled.p`
  font-size: 20px;
  color: #fff;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const ModalTextSubTitle = styled.p`
  font-size: 16px;
  color: #C2C9D6E5;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;


export const ModalLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #BDC7DB1A;
  border-radius: 12px;
  padding: 20px;
`;

export const ModalLinkText = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  max-width: 100%;
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  word-break: break-all; 
  white-space: normal; 
  line-height: 20px;
  margin-bottom: 4px;
`;

export const CopyButton = styled.button`
  background-color: #aade16;
  color: #000;
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #98c314;
  }
`;

export const DisabledJoinButton = styled(JoinButton)`
  color: #999; 
  border-color: #999;
  cursor: not-allowed; 
  background-color: transparent;
`;

// Stores Link 

export const StoresLink = styled.a`
  color: #AADE16;
  text-decoration: underline;
  cursor: pointer;
`;


// Highlight text 

export const HighlightText = styled.span`
  color: #AADE16;
`;

// social icom wrapper

export const InstructionBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const InfoTreeTitleWrapper = styled.div``;

export const InfoTreeTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  @media (max-width: 768px) {
    margin-bottom: 32px;
    font-size: 20px;
  }
`;

export const InfoTreeSubtitle = styled.div`
  font-size: 20px;
  color: #c4f538;
  line-height: 24px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const InfoTreeText = styled.div`
  font-size: 16px;
  color: #e0e4ebcc;
  line-height: 22px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  gap: 6px;
`;

export const SocialIconWrapper = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #BDC7DB21;
`;