import { Container, Wrapper } from 'src/components/Container/styled';
import { Footer } from 'src/components/Footer';
import { FooterDesktop } from 'src/components/FooterDesktop';
import { Header } from 'src/components/Header';
import { HeaderDesktop } from 'src/components/HeaderDesktop';
import { useDeviceType } from 'src/hooks/useDeviceType';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ScrollToTopIcon } from 'src/assets/icons/scroll.svg';

import {
  DataItemHeader,
  DataItemPoint,
  DataItemPoints,
  DataItemStyled,
  DataItemTitle,
  DescriptionStyled,
  Link,
  ListElement,
  ScrollToTop,
  TitleStyled,
  UpdateInfoStyled,
} from './styled';
import DataCollectionTable from './components/DataCollectionTable';
import CookieTable from './components/CookiesTable';

function PrivacyPolicy() {
  const { isMobile } = useDeviceType();
  const [scrollVisible, setScrollVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      if (isMobile && window.scrollY > (windowHeight / 100) * 5) {
        setScrollVisible(true);
      } else {
        setScrollVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  return (
    <Wrapper>
      {scrollVisible && (
        <ScrollToTop onClick={() => window.scrollTo(0, 0)}>
          <ScrollToTopIcon width={'12.267vw'} height={'12.267vw'} />
        </ScrollToTop>
      )}
      <div style={{ flex: 1 }}>
        {isMobile ? <Header isMain /> : <HeaderDesktop isMain />}
        <Container $isMain={isMobile}>
          <TitleStyled>Haqabi privacy notice </TitleStyled>
          <UpdateInfoStyled>Last Updated: 14 June 2024</UpdateInfoStyled>
          <DescriptionStyled>
            This privacy notice outlines the information collected by Haqqex
            Limited (“we”, “us”, “our”) and how we use this information.
          </DescriptionStyled>
          <DescriptionStyled>
            This privacy notice applies to Haqabi Wallet App and
            https://haqabi.com/. We will inform you of other use of your
            personal data not covered by this notice, and where the processing
            is based on consent, we will seek your approval. This privacy notice
            may be updated at any time. We strongly encourage you to visit this
            section of our site for the latest version of our privacy notice.
          </DescriptionStyled>
          <DescriptionStyled>
            Navigate this privacy notice to understand:
          </DescriptionStyled>
          <DataItemStyled>
            <DataItemPoints>
              <DataItemPoint>1. Who we are and how to contact us</DataItemPoint>
              <DataItemPoint>2. Scope of this privacy notice</DataItemPoint>
              <DataItemPoint>
                3. Why we collect and process your personal data
              </DataItemPoint>
              <DataItemPoint>4. Cookies</DataItemPoint>
              <DataItemPoint>
                5. Data Sharing, third-party services and international
                transfers
              </DataItemPoint>
              <DataItemPoint>
                6. Technical & organisational measures to secure personal data
              </DataItemPoint>
              <DataItemPoint>7. Children data</DataItemPoint>
              <DataItemPoint>
                8. Rights you can exercise with regards to your data and how to
                apply them
              </DataItemPoint>
            </DataItemPoints>
          </DataItemStyled>
          <DataItemTitle>1. Who we are and how to contact us</DataItemTitle>
          <DataItemHeader>
            Responsible for your personal data is:
          </DataItemHeader>
          <DescriptionStyled>Haqqex Limited</DescriptionStyled>
          <DescriptionStyled>
            <DataItemHeader>Registered address</DataItemHeader> A3, Bel Etang{' '}
            <br /> Hermitage <br /> Mont Fleuri <br /> Mahe <br />
            Seychelles{' '}
            <DataItemHeader>
              Registration number in the Register of Legal Entities:
            </DataItemHeader>
            Registration number 238682
          </DescriptionStyled>
          <DescriptionStyled>
            If you have additional questions or require more information about
            our privacy notice, do not hesitate to contact us directly at
            support@haqabi.com
          </DescriptionStyled>
          <DataItemHeader> RepresentativeEU Representative</DataItemHeader>
          <DescriptionStyled>
            We have appointed{' '}
            <span style={{ fontWeight: 'bold' }}>TechGDPR DPC GmbH</span> as our
            EU representative under Article 27 of the GDPR. Our representative
            can be contacted via the details below:
          </DescriptionStyled>
          <DataItemHeader>Registered address:</DataItemHeader>
          <DataItemPoint>TechGDPR DPC GmbH,</DataItemPoint>
          <DataItemPoint>Willy-Brandt-Platz 2,</DataItemPoint>
          <DataItemPoint>12529 Berlin-Schönefeld,</DataItemPoint>
          <DataItemPoint>Germany</DataItemPoint>
          <Link href="mailto:haqqex.rep@techgdpr.com">
            haqqex.rep@techgdpr.com
          </Link>
          <DataItemTitle>2. Scope of this privacy notice</DataItemTitle>
          <DescriptionStyled>
            This privacy notice applies to the following categories of people:
          </DescriptionStyled>
          <DataItemHeader>Interested parties</DataItemHeader>
          <DataItemPoints>
            <ul>
              <DataItemPoint>
                <ListElement>
                  Visitors to our website who browse our site
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Users of our product, the Haqabi Wallet
                </ListElement>
              </DataItemPoint>
            </ul>
          </DataItemPoints>
          <DataItemHeader>This notice does not apply to :</DataItemHeader>
          <DataItemPoints>
            <ul>
              <DataItemPoint>
                <ListElement>Our employees</ListElement>
              </DataItemPoint>
            </ul>
          </DataItemPoints>
          <DataItemTitle>3. Why we collect and process your data</DataItemTitle>
          <DataCollectionTable />
          <DescriptionStyled>
            We may analyse public blockchain data to ensure users utilising our
            services are not engaged in illegal or prohibited activity under our
            Terms, and for research and development purposes.
          </DescriptionStyled>

          <DataItemTitle>4. Cookies</DataItemTitle>
          <DescriptionStyled>
            Cookies refer to small pieces of text files sent to your web browser
            by a website you visit. Cookies are stored in your browser and allow
            us or a third party to recognise you, to personalise and make your
            next visit easier, recognise your device and store some information
            about your preferences or past actions. Cookies could either be
            &quot;persistent&quot; or &quot;session&quot; cookies. While session
            cookies are deleted at the end of the session, persistent cookies
            remain stored in the device until they expire.
          </DescriptionStyled>
          <DescriptionStyled>
            When you visit our website, and to ensure the functionality of, and
            facilitate your navigation through our website, we process your data
            by the use of functional cookies. For this purpose, we rely on our
            legitimate interest. In all other instances, including for
            analytical and marketing purposes, we rely on your consent to
            process your data through the use of cookies.
          </DescriptionStyled>
          <DescriptionStyled>
            The table below contains the cookies we make use of:
          </DescriptionStyled>
          <CookieTable />

          <DataItemTitle>
            5. Data Sharing, third-party services and international transfers
          </DataItemTitle>
          <DataItemHeader>Third party service providers</DataItemHeader>
          <DescriptionStyled>
            We rely on the following third-party services:
          </DescriptionStyled>
          <ul>
            <DataItemPoints>
              <DataItemPoint>
                {' '}
                <ListElement>Vonage</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>AWS SNS service</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Auth0</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Firebase (Google)</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Appsflyer</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Hastody.com</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Etherscan.io</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Tronscan.org</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Bscscan.com</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Explorer.haqq.network</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Onramper</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Sentry</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>AWS servers (Germany)</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Amplitude</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Google Analytics platform</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Google Workspace</ListElement>{' '}
              </DataItemPoint>
              <DataItemPoint>
                {' '}
                <ListElement>Zero One Digital Agency</ListElement>{' '}
              </DataItemPoint>
            </DataItemPoints>
          </ul>
          <DescriptionStyled>
            By using our products, be aware that personal data may be
            transferred to the USA and other countries which do not provide a
            level of data protection equal to your country of residence.
          </DescriptionStyled>

          <DataItemTitle>
            6. Technical & organisational measures to secure personal data
          </DataItemTitle>
          <DescriptionStyled>
            To protect your personal data, we rely on multiple security
            measures.
          </DescriptionStyled>
          <DescriptionStyled>
            At Haqabi, we implement a documented risk management program to
            identify threats, rate them and deploy mitigation strategies.
            Employees commit to strict confidentiality and are bound by internal
            policies with measures around password requirements, multi-factor
            authentication, segregation of duties to avoid conflict of interest
            in authorised accesses and user rights control and review. Our
            network is divided into multiple security zones with network access
            control policies enforced.
          </DescriptionStyled>
          <DescriptionStyled>
            The list below contains a general summary of the technical and
            organisational measures we make use of at Haqabi to ensure the
            security of personal data and information:
          </DescriptionStyled>
          <ul>
            <DataItemPoints>
              <DataItemPoint>
                <ListElement>Dedicated security team</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Offboarding policy with termination checklist
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Single unique user account per employee
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  User right assignments performed based on the segregation of
                  duties principle
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Segregation of internal networks with a firewall system
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Usage of a virtual private network (VPN) with authentication
                  mechanism
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Web Application Firewall to protect customers
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>API key</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>Oauth authorization</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>Patch and vulnerability management</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Periodic control of user access rights
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Authorization granted according to the least privileges and
                  need-to-know principles
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>Logging if granted authorizations</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Recording of administrative activities
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Separation of development, testing and production systems
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Network segmentation into multiple network zones
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Encryption of confidential and personal data during
                  transmission using TLSv1.3
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Secure data transmission (SFTP, SSH, TLS, VPN)
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>Mandatory secrecy of the private keys</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>Internal encryption key management</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Encryption keys for at rest encryption stored in hardened Key
                  Management System
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Usage of a virtual private network (VPN) enforced
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Secure data transmission (SFTP, SSH, TLS, VPN)
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Restriction of the authorised person group for transmission
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>Disaster recovery plan</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Implementation of anti-malware solution
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Implementation of infrastructure monitoring tools
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>Code Audits</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>External Penetration Tests</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Annual information security and data protection awareness
                  training at least annually
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  {' '}
                  Requirement of signed confidentiality agreements, service
                  provider agreements
                </ListElement>
              </DataItemPoint>
            </DataItemPoints>
          </ul>

          <DataItemTitle>7. Children data</DataItemTitle>
          <DescriptionStyled>
            This site is not intended for use by persons under the age of 18.
            Neither the Haqabi Wallet nor any of the services we render are
            intended for use by children. If you become aware that a person
            under the age of 18 is using our service, please write to us using
            the contact details provided and we will immediately take action to
            investigate and / or discontinue such use.
          </DescriptionStyled>

          <DataItemTitle>
            8. Rights you can exercise with regards to your data and how to
            apply them
          </DataItemTitle>
          <DataItemHeader>8.1 What rights are available to you?</DataItemHeader>
          <DescriptionStyled>
            Relative to the processing activities outlined above (in the table
            provided in section 3), and provided that we can identify you, the
            following rights and what they afford are available to you:
          </DescriptionStyled>
          <DataItemHeader>The rights of access</DataItemHeader>
          <DescriptionStyled>
            You have the right to obtain, the following information from us:
          </DescriptionStyled>

          <ul>
            <DataItemPoints>
              <DataItemPoint>
                <ListElement>
                  What data is being processed about you;
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>The purposes those activities serve;</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>The recipients of the data;</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>How long the data is needed and kept;</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  Whether automated decision making is made that impacts your
                  freedom and liberties;
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  What applicable safeguards are applied when transferring your
                  data and a copy of these documents.
                </ListElement>
              </DataItemPoint>
            </DataItemPoints>
          </ul>
          <DataItemHeader>The right to rectification</DataItemHeader>
          <DescriptionStyled>
            You have the right to request the rectification of inaccurate data
            or the completion of incomplete data.
          </DescriptionStyled>
          <DataItemHeader style={{ color: 'red' }}>
            The right to erasure
          </DataItemHeader>
          <DescriptionStyled>
            You have the right to request that we delete your data in the
            following cases:
          </DescriptionStyled>
          <ul>
            <DataItemPoints>
              <DataItemPoint>
                <ListElement>Data is no longer necessary</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  You have objected to the processing under our legitimate
                  interests and we have not been able to provide convincing
                  evidence that these interests override your freedoms and
                  liberties
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  The processing takes place unlawfully (i.e., outside of the
                  cases described in section 3)
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  We have collected data from publicly accessible sources
                </ListElement>
              </DataItemPoint>
            </DataItemPoints>
          </ul>
          <DescriptionStyled>
            We will not, however, be able to delete the data in the following
            cases:
          </DescriptionStyled>
          <ul>
            <DataItemPoints>
              <DataItemPoint>
                <ListElement>Were we are unable to identify you;</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  In the instance of a running contract in which you are a party
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  We must retain your information to comply with existing law
                  (e.g., tax or invoice records if your data as a fleet manager
                  appears in our sales and payment documents);
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  For the establishment, exercise, or defence of legal claims.
                </ListElement>
              </DataItemPoint>
            </DataItemPoints>
          </ul>
          <DescriptionStyled>
            The use of digital assets by the users of Haqabi is recorded on a
            public blockchain. Public blockchains can be regarded as distributed
            ledgers, created for the purpose of recording virtual asset related
            transactions. The decentralised nature of blockchains leave room for
            auditing and forensic analysis which can point to the identification
            and location of transacting individuals and associated data.
            <span style={{ color: 'red' }}>
              {' '}
              Due to the decentralised nature of blockchains, information or
              data logged onto it CANNOT BE DELETED OR ERASED, REVISED, OR
              ALTERED by Haqabi.
            </span>
          </DescriptionStyled>
          <DataItemHeader>The right to restriction</DataItemHeader>
          <DescriptionStyled>
            You have the right to request that we mark your data (restrict)
            against further processing in the following cases:
          </DescriptionStyled>
          <ul>
            <DataItemPoints>
              <DataItemPoint>
                <ListElement>You contest the accuracy of the data;</ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  You believe the processing we carry out is unlawful;
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  You believe your data is no longer needed relative to the
                  original purpose of its collection.
                </ListElement>
              </DataItemPoint>
            </DataItemPoints>
          </ul>
          <DataItemHeader>The right to portability</DataItemHeader>
          <DescriptionStyled>You have the following rights:</DescriptionStyled>
          <ul>
            <DataItemPoints>
              <DataItemPoint>
                <ListElement>
                  To receive the personal data we hold about you in a
                  structured, commonly used, machine-readable format;
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  To request the direct transmission of that data to another
                  organisation of your choosing
                </ListElement>
              </DataItemPoint>
            </DataItemPoints>
          </ul>
          <DataItemHeader>The right to object</DataItemHeader>
          <DescriptionStyled>
            You have the right to object to processing based on processing
            carried out in our legitimate interest and we will halt further
            processing (no longer process the data) until we have been able to
            demonstrate compelling legitimate grounds overriding your rights and
            freedoms.
          </DescriptionStyled>
          <DataItemHeader>
            The right to lodge a complaint with a supervisory authority
          </DataItemHeader>
          <DescriptionStyled>
            Regardless of the restrictions listed above or the legal grounds for
            the processing of data listed in the table above, you have the right
            to contact a data protection authority of your choice and formulate
            a complaint. No restrictions are keeping you from exercising this
            right but we kindly request that you do so in cases where you have
            contacted us with the request to exercise one or more of the rights
            listed above and
          </DescriptionStyled>
          <ul>
            <DataItemPoints>
              <DataItemPoint>
                <ListElement>
                  You have not heard back from us within a month of your
                  request;
                </ListElement>
              </DataItemPoint>
              <DataItemPoint>
                <ListElement>
                  You have heard back from us but do not find our response
                  satisfactory.
                </ListElement>
              </DataItemPoint>
            </DataItemPoints>
          </ul>
          <DataItemTitle>8.2 How can you exercise your rights?</DataItemTitle>
          <DataItemHeader>Contacting us</DataItemHeader>
          <DescriptionStyled>
            You may contact us directly by email at{' '}
            <MailLink href="mailto:support@haqabi.com">
              support@haqabi.com
            </MailLink>
            .
          </DescriptionStyled>
        </Container>
      </div>
      {isMobile ? <Footer isMain /> : <FooterDesktop isMain />}
    </Wrapper>
  );
}

const MailLink = styled.a`
  color: var(--color-primary-5);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-size: 4.333vw;
  cursor: pointer;
  @media (min-width: 769px) {
    font-size: 1vw;
  }
`;

export default PrivacyPolicy;
