import { useNavigate } from 'react-router-dom';
import { Container } from 'src/components/Container/styled';
import styled from 'styled-components';
import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/play-store.svg';
import { ReactComponent as AppStoreIcon } from 'src/assets/icons/app-store.svg';
import { ReactComponent as DownloadApkIcon } from 'src/assets/icons/download-apk.svg';

export const GetBonus = () => {
  const navigate = useNavigate();
  return (
    <Container id="Bonus">
      <TitleStyled>Exciting news! 🚀</TitleStyled>
      <InfoTextStyled>
        Our official app is now available on Google Play Store and App Store.
      </InfoTextStyled>
      <InfoTextStyled>
        {` Explore all the features designed to enhance your experience. Don't miss
        out on this opportunity to streamline your interactions with us and
        unlock a world of crypto at your fingertips.`}
      </InfoTextStyled>
      <InfoTextStyled>
        Download now and enjoy your user experience!
      </InfoTextStyled>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <DownloadElementWrapper
          href="https://play.google.com/store/apps/details?id=com.haqabi.mobilewallet"
          target="_blank">
          <PlayMarketIcon width={'5.333vw'} height={'5.333vw'} />
          <DowloadText>Google play</DowloadText>
        </DownloadElementWrapper>
        <DownloadElementWrapper
          target="_blank"
          href="https://apps.apple.com/app/haqabi-wallet/id6477534740">
          <AppStoreIcon width={'5.333vw'} height={'5.333vw'} />
          <DowloadText>App store</DowloadText>
        </DownloadElementWrapper>
        <DownloadElementWrapper
          style={{ gap: '1.01vw' }}
          onClick={() => navigate('/download-apk')}>
          <DowloadText>APK</DowloadText>
          <DownloadApkIcon width={'5.333vw'} height={'5.333vw'} />
        </DownloadElementWrapper>
      </div>
    </Container>
  );
};

const TitleStyled = styled.p`
  font-size: 4.8vw;
  color: #aade16;
  font-weight: 500;
  margin-bottom: 2.133vw;
  margin-top: 21.333vw;
`;

const InfoTextStyled = styled.p`
  color: #eaedee;
  font-size: 3.733vw;
  line-height: 4.8vw;
  margin-bottom: 2.133vw;
  &:nth-child(3) {
    margin-bottom: 4.2vw;
  }
`;

const DownloadElementWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.733vw 3.2vw;
  border-radius: 2.133vw;
  border-color: #6a7484;
  border-width: 1px;
  border-style: solid;
  background-color: #29313e;
  gap: 2.133vw;
  opacity: ${({ disabled }) => (disabled ? '0.8' : '1')};
`;

const DowloadText = styled.p`
  font-size: 3.2vw;
  color: #eaedee;
  font-family: Inter, sans-serif;
  white-space: nowrap;
`;
