import cx from 'classnames';

import s from '../index.module.scss';

const stylesBySize = {
  xs: cx(s.xs, 'px-8 radius-4 text-s10 lh-unset'),
  sm: cx(s.sm, 'px-8 radius-4 text-s12 lh-unset'),
  md: cx(s.md, 'px-12 radius-4 text-s14 lh-unset'),
  lg: cx(s.lg, 'px-16 radius-8 text-s14 lh-unset'),
  xl: cx(s.xl, 'px-16 radius-8 text-s16 lh-unset'),
};

const stylesBg = {
  primary: 'bg-primary-5',
  positive: 'bg-positive-5',
  negative: 'bg-negative-5',
  neutral: 'bg-neutral-9',
  transparent: 'bg-transparent',
};

const stylesColor = {
  neutral: cx(s.colorNeutral, 'color-neutral-10'),
  primary: cx(s.colorPrimary, 'color-primary-5'),
  white: cx(s.colorWhite, 'color-neutral-1'),
};

const stylesBorder = {
  primary: 'border-color-primary-8',
  neutral: 'border-color-neutral-7',
  transparent: 'border-color-transparent',
};

const fillDisabled = 'bg-neutral-10 color-neutral-6 cursor-default';
const outlineDisabled =
  'bg-transparent color-neutral-4 border border-color-neutral-6 cursor-default';

export function getClasses(size, bg, color, border, disabled) {
  if (disabled) {
    if (border === 'transparent') {
      return cx(stylesBySize[size], fillDisabled);
    } else {
      return cx(stylesBySize[size], outlineDisabled);
    }
  }
  return cx(
    'border',
    s.hover,
    s.active,
    stylesBySize[size],
    stylesBg[bg],
    stylesColor[color],
    stylesBorder[border],
  );
}
