import { Container } from 'src/components/Container/styled';
import { ReactComponent as ProjectTimelineImage } from 'src/assets/icons/project-timeline.svg';
import styled from 'styled-components';

export const TitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 2.778vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3.333vw;
`;

export const ProjectTimelineDesktop = () => {
  return (
    <Container id="Timeline" style={{ margin: '9.722vw 0' }}>
      <TitleStyled>Project timeline</TitleStyled>
      <ProjectTimelineImage width={'100%'} height={'100%'} />
    </Container>
  );
};
