import React from 'react';

import cx from 'classnames';

import useIntl from 'src/components/CMS/intl/useIntl';
import { getClasses } from './util';

const Button = (props, ref) => {
  const {
    className,
    font = 'sf-ui',
    size = 'md',
    bg,
    color,
    border,
    disabled = false,
    message,
    children,
    startAdornment,
    endAdornment,
    onClick,
    type,
  } = props;

  const intl = useIntl();

  const classes = cx(
    'flex items-center justify-center transition',
    font,
    disabled ? 'pointer-none' : 'pointer',
    getClasses(
      size,
      bg || 'transparent',
      color || 'white',
      border || 'transparent',
      disabled,
    ),
    className,
  );
  let content = children;

  if (message) {
    if (typeof message === 'string') {
      content = message;
    } else {
      const { values, ...intlMessage } = message || {};

      content = intl.formatMessage(intlMessage, values);
    }
  }

  return React.createElement(
    'button',
    {
      className: classes,
      type,
      onClick: disabled ? () => {} : onClick,
      ref,
    },
    ...[startAdornment, content, endAdornment],
  );
};

Button.displayName = 'Button';

export default React.memo(React.forwardRef(Button));
