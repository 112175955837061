import React, { useMemo } from 'react';
import cx from 'classnames';

import images from './util/images';
import s from './index.module.scss';
import { useBreakpoint } from 'src/hooks/useBreakpoint.js';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate } from './util/formatDate';
import { Button, Text } from '..';
import Image from '../Image';

const POSTS_PER_PAGE = 6;

const ArticlesList = props => {
  const { posts, page, countOfPosts, tag } = props;
  const navigate = useNavigate();

  const { md, lg } = useBreakpoint();

  const getPages = useMemo(
    () => (totalPages, currentPage) => {
      const maxDisplayPages = 5;
      const half = Math.floor(maxDisplayPages / 2);

      let startPage = Math.max(1, currentPage - half);
      const endPage = Math.min(totalPages, startPage + maxDisplayPages - 1);

      startPage = Math.max(1, endPage - maxDisplayPages + 1);

      const arrayOfPages = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i,
      );

      return arrayOfPages.length ? arrayOfPages : [1];
    },
    [],
  );

  const changePage = newPage => {
    if (newPage === Number(page)) {
      return;
    }
    const newParams = new URLSearchParams(window.location.search);
    newParams.set('page', newPage);

    // Build the new URL
    const newUrl = `${window.location.pathname}?${newParams.toString()}`;

    // Navigate to the new URL
    navigate(newUrl);
  };

  const pageList = useMemo(
    () => getPages(countOfPosts / POSTS_PER_PAGE, Number(page)),
    [countOfPosts, page],
  );

  return (
    <>
      <div
        className={cx('grid-gap-32 mt-32', {
          'flex flex-col': !md,
          'grid grid-col-2': md,
          'grid grid-col-3': lg,
        })}>
        {posts?.map((item, index) => (
          <div
            onClick={() => navigate(`/education/${item.slug}`)}
            className={cx(
              s.article,
              'radius-12 border border-color-neutral-7 overflow-hidden pointer flex flex-col',
            )}
            key={index}>
            <div
              className={cx(s.preview, 'bg-cover pointer')}
              style={{ backgroundImage: `url(${item.featured_image || ''})` }}
            />
            <div className="p-16">
              <Text size="m20" color="neutral-1" message={item.title} />
              <Text
                size="l16"
                color="neutral-3"
                message={item.summary ? item.summary : '-'}
                className="mt-16"
              />
              <div className="flex items-center mt-16">
                <Text
                  size="l16"
                  color="neutral-3"
                  message={formatDate(new Date(item.published))}
                />
                <Image src={images.clockIcon} size={18} className="ml-16" />
                <Text
                  size="l16"
                  color="neutral-3"
                  message="7 min"
                  className="ml-8"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center grid-gap-12 mt-96">
        <Button
          className="px-4 aspect-square"
          type="button"
          onClick={() => changePage(Number(page) - 1)}
          disabled={Number(page) === 1}
          bg="neutral"
          size="md">
          <Image
            src={images.leftIcon}
            size={24}
            color={Number(page) === 1 ? 'neutral-4' : 'neutral-1'}
          />
        </Button>

        {pageList?.map(item => (
          <Button
            color={item === Number(page) ? 'neutral' : undefined}
            bg={item === Number(page) ? 'primary' : undefined}
            key={item}
            message={item.toString()}
            onClick={() => changePage(item)}
            size="md"
          />
        ))}
        <Button
          className="px-4 aspect-square"
          type="button"
          onClick={() => changePage(Number(page) + 1)}
          disabled={Number(page) === pageList.length}
          size="md"
          bg="neutral">
          <Image
            src={images.rightIcon}
            size={24}
            color={
              Number(page || 1) === pageList.length ? 'neutral-4' : 'neutral-1'
            }
          />
        </Button>
      </div>
    </>
  );
};

export default React.memo(ArticlesList);
