import { ReactComponent as AvalancheIcon } from 'src/assets/icons/avalanche.svg';
import { ReactComponent as BinanceIcon } from 'src/assets/icons/binance.svg';
import { ReactComponent as BitcoinCoinIcon } from 'src/assets/icons/bitcoin-coin.svg';
import { ReactComponent as FlagIcon } from 'src/assets/icons/flag.svg';
import { ReactComponent as XRPIcon } from 'src/assets/icons/xrp.svg';
import { ReactComponent as AdaNetworkIcom } from 'src/assets/icons/ada.svg';
import { ReactComponent as MaticIcon } from 'src/assets/icons/matic.svg';
import { ReactComponent as LitecoinIcon } from 'src/assets/icons/litecoin.svg';
import { ReactComponent as AtomIcon } from 'src/assets/icons/atom.svg';
import { ReactComponent as DotIcon } from 'src/assets/icons/dot.svg';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  CardItem,
  ItemStyled,
  PeriodStyled,
  TitleStyled,
  YearStyled,
  ProjectTimeLineWrapper,
} from './styled';
import { Container } from 'src/components/Container/styled';
import { ReactComponent as SolanaIcon } from 'src/assets/icons/solana.svg';
import styled from 'styled-components';

const HorizontalView = styled.div`
  display: flex;
  align-items: center;
  gap: 2.133vw;
`;
const iconSize = { width: '5.333vw', height: '5.333vw' };

export const ProjectTimeline = () => {
  return (
    <Container id="Timeline" style={{ marginTop: '26.667vw' }}>
      <ProjectTimeLineWrapper>
        <TitleStyled>Project timeline</TitleStyled>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}>
          {data.map((timeline, index) => (
            <SwiperSlide key={index}>
              <CardItem Item>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2.133vw',
                  }}>
                  <PeriodStyled>{timeline.period}</PeriodStyled>
                  <YearStyled>{timeline.year}</YearStyled>
                </div>
                <ul
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2.133vw',
                  }}>
                  {timeline.items.map((item, itemIdx) => {
                    if (typeof item === 'object') {
                      return (
                        <div
                          key={itemIdx}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2.133vw',
                          }}>
                          <ItemStyled>{item.title}</ItemStyled>
                          {item.icon}
                        </div>
                      );
                    } else {
                      if (item === 'Launch of the wallet') {
                        return (
                          <div
                            key={itemIdx}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '3.2vw',
                            }}>
                            <FlagIcon width={'2.933vw'} height={'2.933vw'} />
                            <ItemStyled $primary key={itemIdx}>
                              {item}
                            </ItemStyled>
                          </div>
                        );
                      }
                      return <ItemStyled key={itemIdx}>{item}</ItemStyled>;
                    }
                  })}
                </ul>
              </CardItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </ProjectTimeLineWrapper>
    </Container>
  );
};

const data = [
  {
    period: 'Q1',
    year: 2024,
    items: [
      {
        title: 'Bitcoin & BNB networks',
        icon: (
          <HorizontalView>
            <BitcoinCoinIcon {...iconSize} />
            <BinanceIcon {...iconSize} />
          </HorizontalView>
        ),
      },
      {
        title: 'Buying crypto by fiat',
      },
      'Launch of the wallet',
    ],
  },

  {
    period: 'Q2',
    year: 2024,
    items: [
      {
        title: 'Solana Network',
        icon: <SolanaIcon width={'5.333vw'} height={'5.333vw'} />,
      },
      'NFT',
      'Staking',
    ],
  },
  {
    period: 'Q2',
    year: 2024,
    items: [
      {
        title: 'Swaps CEX',
      },
      {
        title: 'Ripple network',
        icon: <XRPIcon {...iconSize} />,
      },
    ],
  },
  {
    period: 'Q3',
    year: 2024,
    items: [
      {
        title: 'Merchant service',
      },
      {
        title: 'ADA & MATIC networks',
        icon: (
          <HorizontalView>
            <AdaNetworkIcom {...iconSize} />
            <MaticIcon {...iconSize} />
          </HorizontalView>
        ),
      },
      { title: 'Avalanche network', icon: <AvalancheIcon {...iconSize} /> },
    ],
  },
  {
    period: 'Q4',
    year: 2024,
    items: [
      {
        title: 'Litecoin Network',
        icon: <LitecoinIcon {...iconSize} />,
      },
      {
        title: 'ATOM & DOT networks',
        icon: (
          <HorizontalView>
            <AtomIcon {...iconSize} />
            <DotIcon {...iconSize} />
          </HorizontalView>
        ),
      },
      {
        title: 'Token issuance',
      },
    ],
  },
  {
    period: 'Q1',
    year: 2025,
    items: [
      {
        title: 'AI prediction',
      },
    ],
  },
];
