import { useRef, useState } from 'react';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';
import { featureData } from './data';
import {
  ArrowIconStyled,
  BlockBoxStyled,
  BoxStyled,
  AccordionItemStyled,
  ContentBoxStyled,
  AccordionBody,
  TextTypographyStyled,
  TitleStyled,
  AccordionCollapse,
  AccordionHeader,
} from './styled';
import { Container } from 'src/components/Container/styled';

export const Features = () => {
  const [openedContents, setOpenedContents] = useState([]);

  function toggleAccordion(index) {
    if (openedContents.includes(index)) {
      setOpenedContents(openedContents.filter(el => el !== index));
    } else {
      setOpenedContents([...openedContents, index]);
    }
  }

  return (
    <Container id="Features">
      <BoxStyled>
        <TextTypographyStyled>Features</TextTypographyStyled>
        <BlockBoxStyled>
          <ContentBoxStyled>
            {featureData.map((feature, idx) => (
              <AccordionItem
                key={idx}
                feature={feature}
                idx={idx}
                toggleAccordion={toggleAccordion}
                openedContents={openedContents}
              />
            ))}
          </ContentBoxStyled>
        </BlockBoxStyled>
      </BoxStyled>
    </Container>
  );
};

const AccordionItem = ({ feature, idx, toggleAccordion, openedContents }) => {
  const itemRef = useRef(null);
  const isOpen = openedContents.includes(idx);

  return (
    <AccordionItemStyled onClick={() => toggleAccordion(idx)}>
      <AccordionHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4.267vw',
          }}>
          {feature.icon}
          <TitleStyled>{feature.title}</TitleStyled>
        </div>
        <ArrowIconStyled $isOpen={openedContents.includes(idx)}>
          <ArrowIcon
            color={isOpen ? '#AADE16' : 'white'}
            opacity={isOpen ? '1' : '0.5'}
            width={24}
            height={24}
          />
        </ArrowIconStyled>
      </AccordionHeader>
      <AccordionCollapse
        style={
          isOpen ? { height: itemRef.current.scrollHeight } : { height: 0 }
        }
        $isOpen={openedContents.includes(idx)}>
        <AccordionBody ref={itemRef}>{feature.description}</AccordionBody>
      </AccordionCollapse>
    </AccordionItemStyled>
  );
};
