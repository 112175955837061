import styled from 'styled-components';
import haqabiDesktopBackground from 'src/assets/img/haqabi-bg-desktop.png';

export const Wrapper = styled.div`
  min-height: 100vh;
  background: #090d12;
  width: 100%;
  height: 100%;
  padding: 1.111vw 0 16vw;
  @media (min-width: 769px) {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url(${haqabiDesktopBackground});
    padding: 3.889vw 0 6.25vw;
    background-size: 90%;
  }
`;

export const Container = styled.div`
  padding: 0 6.944vw;
`;
export const DeleteAccountAccordionWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid var(--color-neutral-9, #29313e);
  background: var(--color-neutral-11, #171d25);
  backdrop-filter: blur(9.5px);
  margin-top: 4.533vw;
  padding: 4.267vw 6.4vw;
  @media (min-width: 769px) {
    margin-top: 4.167vw;
    padding: 1.667vw 1.111vw;
  }
`;
export const TitleStyled = styled.div`
  color: var(--color-primary-5);
  font-size: 3.733vw;
  font-weight: 500;
  margin-left: 4.267vw;
  @media (min-width: 769px) {
    font-size: 0.972vw;
    margin-left: 1.111vw;
  }
`;
export const DeleteAccountBodyCollapse = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
`;
export const DeleteAccountBody = styled.div`
  padding-bottom: 6.4vw;
  @media (min-width: 769px) {
    padding-bottom: 1.667vw;
  }
`;

export const InfoTextStyled = styled.p`
  font-size: 3.733vw;
  color: var(--color-neutral-1);
  font-family: Public-Sans, sans-serif;
  line-height: 150%;

  @media (min-width: 769px) {
    font-size: 1.111vw;
  }
`;

export const InfoTextFirstStyled = styled.p`
  font-size: 3.733vw;
  color: var(--color-neutral-1);
  font-family: Public-Sans, sans-serif;
  line-height: 150%;

  border-top: 1px solid var(--color-neutral-10);
  padding-top: 20px;

  @media (min-width: 769px) {
    border: none;
    padding-top: 0;
    font-size: 1.111vw;
  }
`;

export const ListStyled = styled.ul`
  margin-left: 3.3vw;
  li {
    display: flex;
    align-items: baseline;
  }
  li {
    p {
      width: 100%;
    }
  }
  li::before {
    content: '';
    display: block;
    background-color: var(--color-neutral-1);
    margin-right: 2.133vw;
    width: 0.8vw;
    height: 0.8vw;
    border-radius: 50%;
  }
  @media (min-width: 769px) {
    margin-left: 1vw;

    li::before {
      margin-right: 0.556vw;
      width: 0.208vw;
      height: 0.208vw;
    }
  }
`;

export const OrderListStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.133vw;
  @media (min-width: 769px) {
    row-gap: 0.556vw;
  }
`;

export const ArrowIconStyled = styled.div`
  color: transparent;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
