import React from 'react';
import cx from 'classnames';

import logo from 'src/assets/icons/logo.svg';
import loaderIcon from 'src/assets/icons/loader.svg';

import Image from '../Image';

import s from './index.module.scss';

const Loader = props => {
  const { small, className } = props;

  return (
    <div
      className={cx(
        'flex items-center justify-center flex-col',
        className,
        small ? 'h-full' : s.container,
      )}>
      {!small && <Image src={logo} size={{ w: 120, h: 16 }} />}
      <Image src={loaderIcon} size={60} />
    </div>
  );
};

export default Loader;
