import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Text } from './components';
import ArticlesList from './components/articlesList';
import { PAGE_SIZE, fetchData } from 'src/butter-client';
import { BreakpointProvider } from 'src/hooks/useBreakpoint';
import styled from 'styled-components';
import { FooterDesktop } from '../FooterDesktop';
import { useDeviceType } from 'src/hooks/useDeviceType';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { HeaderDesktop } from '../HeaderDesktop';
import Loader from './components/Loader';

const Wrapper = styled.div`
  background: #10151b;
  min-height: 100vh;
`;

export const CMS = () => {
  const [posts, setPosts] = useState(null);
  const [tags, setTags] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  const [queryParameters] = useSearchParams();
  const tag_slug = queryParameters.get('tag_slug');
  const page = queryParameters.get('page') || 1;
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();

  useEffect(() => {
    const fetchCMS = async () => {
      const promisePosts = fetchData('posts', {
        locale: 'en',
        page: page,
        page_size: PAGE_SIZE,
        tag_slug: tag_slug || undefined,
        category_slug: 'haqabi',
      });
      const promiseTags = fetchData('tags', {
        category_slug: 'haqabi',
      });

      try {
        const [posts, tags] = await Promise.all([promisePosts, promiseTags]);
        setPosts(posts);
        setTags(tags.data);
      } catch (e) {
        console.error(e);
      } finally {
        setShowLoader(false);
      }
    };
    fetchCMS();
  }, [page, tag_slug]);

  const handleChangeTag = tag => {
    const newParams = new URLSearchParams(window.location.search);
    if (!tag) {
      newParams.delete('tag_slug');
    } else {
      newParams.set('tag_slug', tag);
    }

    const newUrl = `${window.location.pathname}?${newParams.toString()}`;

    navigate(newUrl);
  };

  return (
    <Wrapper>
      <BreakpointProvider>
        <div className="pt-80">{isMobile ? <Header /> : <HeaderDesktop />}</div>
        <div className="flex flex-col items-center flex-grow-1 w-full px-20 pt-32 pt-80 ">
          <Text size="s26" color="neutral-1" message={'Education'} />
          {showLoader ? (
            <Loader />
          ) : (
            <>
              <div className="flex items-center grid-gap-16 mt-32">
                <Button
                  onClick={() => handleChangeTag(null)}
                  color={!tag_slug ? 'neutral' : 'primary'}
                  bg={!tag_slug ? 'primary' : 'neutral'}
                  message="All"
                />
                {tags?.map(item => (
                  <Button
                    className="capitalize"
                    key={item.name}
                    onClick={() => handleChangeTag(item.slug)}
                    color={item.slug === tag_slug ? 'neutral' : 'primary'}
                    bg={item.slug === tag_slug ? 'primary' : 'neutral'}
                    message={item.name.toLowerCase()}
                  />
                ))}
              </div>
              <ArticlesList
                tag={tag_slug}
                posts={posts?.data}
                page={page}
                countOfPosts={posts?.meta?.count}
              />
            </>
          )}
        </div>
        <div className="pb-80">{isMobile ? <Footer /> : <FooterDesktop />}</div>
      </BreakpointProvider>
    </Wrapper>
  );
};
