export default {
  education: {
    en: 'Education',
    pt: 'Education',
    id: 'Education',
    vi: 'Education',
    ms: 'Education',
    bn: 'Education',
    pa: 'Education',
    tr: 'Education',
  },
  backToEducation: {
    en: 'Back to education',
    pt: 'Voltar à educação',
    id: 'Kembali ke pendidikan',
    vi: 'Quay lại giáo dục',
    ms: 'Kembali ke pendidikan',
    bn: 'শিক্ষায় ফিরে যান',
    pa: 'ਸਿੱਖਿਆ ਵੱਲ ਵਾਪਸ',
    tr: 'Eğitime geri dön',
  },
  articleOnModeration: {
    en: 'Article on moderation',
    pt: 'Artigo sobre moderação',
    id: 'Artikel tentang moderasi',
    vi: 'Bài viết về thẩm tra',
    ms: 'Artikel mengenai kesederhanaan',
    bn: 'সংযম সম্পর্কিত নিবন্ধ',
    pa: "ਸੰਜਮ 'ਤੇ ਲੇਖ",
    tr: 'Moderasyonla ilgili makale',
  },
  share: {
    en: 'Share article',
    pt: 'Partilhar',
    id: 'Bagikan artikel',
    vi: 'Chia sẻ bài viết này',
    ms: 'Kongsi artikel',
    bn: 'নিবন্ধ শেয়ার করুন',
    pa: 'ਲੇਖ ਸਾਂਝਾ ਕਰੋ',
    tr: 'Bu makaleyi paylaşın',
  },
  relatedPosts: {
    en: 'Related posts',
    pt: 'Artigos Relacionados',
    id: 'Pos terkait',
    vi: 'Bài viết liên quan',
    ms: 'Berkaitan posting',
    bn: 'সম্পর্কিত পোস্ট',
    pa: 'ਸਬੰਧਤ ਪੋਸਟ',
    tr: 'İlgili Gönderiler',
  },
};
