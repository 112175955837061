import React, { Children } from 'react';
import cx from 'classnames';

import Image from '../Image';
import images from './util/images';
import insertSeparators from './util/insertSeparators';

const Breadcrumbs = ({
  children,
  separator = <Image src={images.arrowIcon} size={16} color="neutral-5" />,
  className,
}) => {
  return (
    <div className={cx('flex items-center grid-gap-8', className)}>
      {insertSeparators(Children.toArray(children), separator)}
    </div>
  );
};

Breadcrumbs.displayName = 'Breadcrumbs';

export default React.memo(Breadcrumbs);
