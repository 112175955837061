import React from 'react';

export default (items, separator) => {
  return items.reduce((acc, current, index) => {
    const currentWithKey = React.cloneElement(current, { key: index });
    const separatorWithKey = React.cloneElement(separator, {
      key: index + '.$',
    });
    if (index < items.length - 1) {
      acc = [...acc, currentWithKey, separatorWithKey];
    } else {
      acc.push(currentWithKey);
    }
    return acc;
  }, []);
};
