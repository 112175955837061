import { ReactComponent as BankIcon } from 'src/assets/icons/bank.svg';
import { ReactComponent as GlobeIcon } from 'src/assets/icons/globe.svg';
import { ReactComponent as MoonStarIcon } from 'src/assets/icons/moon-star.svg';
import {
  ContentBlockStyled,
  ContentBlockSubTitleStyled,
  ContentBlockTitleStyled,
  ContentBlocksStyled,
  ContentSpecialTextStyled,
  ContentSubTitleStyled,
  ContentTitleStyled,
} from './styled';
import { Container } from 'src/components/Container/styled';

export const PhilosophyDesktop = () => {
  return (
    <Container id="AboutUs">
      <ContentTitleStyled>our philosophy</ContentTitleStyled>
      <ContentSubTitleStyled>
        We are part of the{' '}
        <ContentSpecialTextStyled href="https://www.haqqex.com/en/">
          Haqqex Exchange{' '}
        </ContentSpecialTextStyled>
        family, and our mission is to create a user-friendly crypto wallet that
        caters to a broad audience. <br /> What sets us apart is our commitment
        to Islamic ethics, which ensures transparent and secure transactions.
      </ContentSubTitleStyled>

      <ContentBlocksStyled>
        <ContentBlockStyled>
          <BankIcon style={{ width: '3.333vw', height: '3.333vw' }} />
          <ContentBlockTitleStyled>
            Combination of high security <br /> and user-friendly functionality
          </ContentBlockTitleStyled>

          <ContentBlockSubTitleStyled>
            Our approach combines high security standards with user convenience,
            eliminating common inconveniences in the current market, such as the
            risk of losing access to wallets or the use of complex recovery
            phrases.
          </ContentBlockSubTitleStyled>
        </ContentBlockStyled>
        <ContentBlockStyled>
          <MoonStarIcon style={{ width: '3.333vw', height: '3.333vw' }} />
          <ContentBlockTitleStyled>
            Integrating Islamic Ethics <br /> and Technology
          </ContentBlockTitleStyled>

          <ContentBlockSubTitleStyled>
            In our product development, we embrace ethical principles,
            emphasizing transaction transparency and the absence of concealed
            fees.
          </ContentBlockSubTitleStyled>
        </ContentBlockStyled>
        <ContentBlockStyled>
          <GlobeIcon style={{ width: '3.333vw', height: '3.333vw' }} />
          <ContentBlockTitleStyled>
            Synergy with the Haqqex Exchange Ecosystem{' '}
          </ContentBlockTitleStyled>

          <ContentBlockSubTitleStyled>
            Our wallet users gain full access to the benefits of the exchange`s
            operation and a wide range of cryptocurrencies, including
            Sharia-compliant tokens.
          </ContentBlockSubTitleStyled>
        </ContentBlockStyled>
      </ContentBlocksStyled>
    </Container>
  );
};
