import iphoneMockupImage from 'src/assets/img/iphone-mockup.png';
import { Wrapper } from 'src/components/Container/styled';
import { Difference } from 'src/components/Difference';
// import { Documentation } from 'src/components/Documentation';
import { IphoneMockup } from 'src/components/Documentation/styled';
import { Features } from 'src/components/Features';
import { GetBonus, GetBonusMain } from 'src/components/GetBonus';
import { Header } from 'src/components/Header';
import { HeaderDesktop } from 'src/components/HeaderDesktop';
import { AboutUs } from 'src/components/AboutUs';
import { Philosophy } from 'src/components/Philosophy';
import { PhilosophyDesktop } from 'src/components/PhilosophyDesktop';
import { ProjectTimeline } from 'src/components/ProjectTimeline';
import { useDeviceType } from 'src/hooks/useDeviceType';
import { FooterDesktop } from 'src/components/FooterDesktop';
import { Footer } from 'src/components/Footer';
import { ProjectTimelineDesktop } from 'src/components/ProjectTimelineDesktop';
import { LaunchBoxProgram } from 'src/components/LaunchBoxProgram';
import { FeaturesDesktop } from 'src/components/FeaturesDesktop';
import { SocialMediaLinks } from 'src/components/Links';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Education } from './components/Education';
import { SuccessModal } from './components/SuccessModal';
import { AboutUsDesktop } from './components/AboutUsDesktop';
import { SwapsDesktop } from './components/SwapsDesktop';
import { RecoveryDesktop } from './components/RecoveryDesktop';
import { ProtectionDesktop } from './components/ProtectionDesktop';
import { Swaps } from './components/Swaps';
import { Recovery } from './components/Recovery';
import { Protection } from './components/Protection';

function App() {
  const { state } = useLocation();
  const elementId = state?.elementId;
  const { isMobile } = useDeviceType();
  const [success, setSuccess] = useState(false);
  const [openPopup, setOpenPopup] = useState(success);

  useEffect(() => {
    if (elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  useEffect(() => {
    if (success) {
      setOpenPopup(true);
    }
  }, [success]);

  if (openPopup && isMobile) {
    return <SuccessModal setOpenPopup={setOpenPopup} />;
  }

  return (
    <Wrapper $isMain>
      {isMobile ? <Header /> : <HeaderDesktop />}
      {isMobile ? <AboutUs /> : <AboutUsDesktop />}
      {isMobile ? <Features /> : <FeaturesDesktop />}
      <SocialMediaLinks />
      {isMobile ? <Swaps /> : <SwapsDesktop />}
      {isMobile ? <Recovery /> : <RecoveryDesktop />}
      {isMobile ? <Protection /> : <ProtectionDesktop />}
      <Difference />
      {isMobile ? <ProjectTimeline /> : <ProjectTimelineDesktop />}
      {isMobile ? <Philosophy /> : <PhilosophyDesktop />}
      {isMobile && <GetBonus />}

      {isMobile ? (
        <IphoneMockup src={iphoneMockupImage} />
      ) : (
        <LaunchBoxProgram success={success} setSuccess={setSuccess} />
      )}
      <Education />
      {/* <Documentation /> */}

      {isMobile ? <Footer /> : <FooterDesktop />}
    </Wrapper>
  );
}

export default App;
