import { ReactComponent as BankIcon } from 'src/assets/icons/bank.svg';
import { ReactComponent as GlobeIcon } from 'src/assets/icons/globe.svg';
import { ReactComponent as MoonStarIcon } from 'src/assets/icons/moon-star.svg';
import {
  ContentBlockTextStyled,
  ContentBoxBlockStyled,
  ContentTextStyled,
  SpecialTextStyled,
  ContentWrapper,
} from './styled';
import { Container } from 'src/components/Container/styled';

export const Philosophy = () => {
  return (
    <Container id="AboutUs">
      <ContentWrapper>
        <ContentTextStyled>our philosophy</ContentTextStyled>
        <ContentBoxBlockStyled>
          <GlobeIcon width={'10.667vw'} height={'10.667vw'} />
          <ContentBlockTextStyled>
            Synergy with the Haqqex Ecosystem
          </ContentBlockTextStyled>
        </ContentBoxBlockStyled>
        <ContentBoxBlockStyled>
          <BankIcon width={'10.667vw'} height={'10.667vw'} />
          <ContentBlockTextStyled>
            Combination of high security and user-friendly functionality
          </ContentBlockTextStyled>
        </ContentBoxBlockStyled>
        <ContentBoxBlockStyled>
          <MoonStarIcon width={'10.667vw'} height={'10.667vw'} />
          <ContentBlockTextStyled>
            Integrating Islamic Ethics <br /> and Technology
          </ContentBlockTextStyled>
        </ContentBoxBlockStyled>
      </ContentWrapper>
    </Container>
  );
};
