// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBofgyfdFdKNiLXfFB4nvlwBMeAccZxKzA',
  authDomain: 'haqabi-app.firebaseapp.com',
  databaseURL: 'https://haqabi-app-default-rtdb.firebaseio.com',
  projectId: 'haqabi-app',
  storageBucket: 'haqabi-app.appspot.com',
  messagingSenderId: '1037630468396',
  appId: '1:1037630468396:web:95ea7b161a903dccdafc89',
  measurementId: 'G-JW0PK15MYN',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
