import { CompactTable } from '@table-library/react-table-library/compact';
import styled from 'styled-components';

const RowText = styled.p`
  color: var(--color-neutral-2);
  font-size: 4vw;
  white-space: wrap;
  @media (min-width: 769px) {
    font-size: 1vw;
  }
`;

const ListItem = styled.li`
  list-style: disc;
  font-size: 4vw;

  @media (min-width: 769px) {
    font-size: 1vw;
  }
`;

const Link = styled.a`
  color: var(--color-primary-5);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  font-size: 4vw;
  @media (min-width: 769px) {
    font-size: 1vw;
  }
`;

const ColumnTitle = styled.p`
  color: var(--color-neutral-3);
  font-size: 4.25vw;
  white-space: wrap;
  text-align: center;
  @media (min-width: 769px) {
    font-size: 1.25vw;
  }
`;

const nodes = [
  {
    id: '0',
    row0: <RowText>_ga</RowText>,
    row1: <RowText>1st party</RowText>,
    row2: <RowText>Performance</RowText>,
    row3: <RowText>2 years</RowText>,
  },
  {
    id: '1',
    row0: <RowText>_gid</RowText>,
    row1: <RowText>1st party</RowText>,
    row2: <RowText>Performance</RowText>,
    row3: <RowText>24 hours</RowText>,
  },
  {
    id: '2',
    row0: <RowText>_gat</RowText>,
    row1: <RowText>1st party</RowText>,
    row2: <RowText>Performance</RowText>,
    row3: <RowText>1 minute</RowText>,
  },
  {
    id: '3',
    row0: <RowText>_gac_</RowText>,
    row1: <RowText>1st party</RowText>,
    row2: <RowText>Performance</RowText>,
    row3: <RowText>90 days</RowText>,
  },
  {
    id: '4',
    row0: <RowText>_gat_</RowText>,
    row1: <RowText>1st party</RowText>,
    row2: <RowText>Performance</RowText>,
    row3: <RowText>2 years</RowText>,
  },
];

const TableContainer = styled.div`
  margin: 0 auto;
  overflow-x: scroll;

  table {
    border-collapse: collapse;
    min-width: 1200px;
  }
  @media (min-width: 769px) {
    table {
      width: 100%;
      min-width: auto;
    }
    overflow: auto;
  }

  th,
  td {
    padding: 2.674vw 5.348vw;
    @media (min-width: 769px) {
      padding: 0.694vw 1.389vw;
    }
    border: 1px solid #ddd;
    text-align: left;
  }

  th div,
  td div {
    overflow: visible;
  }

  th {
    background-color: #090d12;
    font-weight: bold;
  }

  td {
    background-color: #090d12;
  }

  /* tr:nth-child(1) td,
  tr:nth-child(3) td,
  tr:nth-child(7) td,
  tr:nth-child(11) td,
  tr:nth-child(18) td {
    background-color: var(--color-neutral-6);
    border: none;
    white-space: nowrap;
  }

  tr:nth-child(1) td div p,
  tr:nth-child(3) td div p,
  tr:nth-child(7) td div p,
  tr:nth-child(11) td div p,
  tr:nth-child(18) td div p {
    white-space: nowrap;
  } */

  tr:hover {
    background-color: #090d12;
  }

  td:first-child,
  th:first-child {
    border-left: 0;
  }

  td:last-child,
  th:last-child {
    border-right: 0;
  }
`;

const COLUMNS = [
  {
    label: <ColumnTitle>Cookie name</ColumnTitle>,
    renderCell: item => item?.row0,
  },
  {
    label: <ColumnTitle>1st / 3rd party</ColumnTitle>,
    renderCell: item => item?.row1,
  },
  {
    label: <ColumnTitle>Category</ColumnTitle>,
    renderCell: item => item?.row1,
  },
  {
    label: <ColumnTitle>Duration</ColumnTitle>,
    renderCell: item => item?.row2,
  },
];

const Table = () => {
  const data = { nodes };
  return (
    <TableContainer>
      <CompactTable columns={COLUMNS} data={data} />
    </TableContainer>
  );
};

export default Table;
