import { css } from '@emotion/react';
import haqabiDesktopBackground from 'src/assets/img/haqabi-bg-desktop.png';
import haqabiBackground from 'src/assets/img/haqabi-bg.png';
import styled from 'styled-components';

const getIsMainWrapper = isMain => {
  return isMain
    ? css`
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 100%;
        height: 100%;
        padding: 1.111vw 0 16vw;
        background-image: url(${haqabiBackground});
        @media (min-width: 769px) {
          background-image: url(${haqabiDesktopBackground});
          padding: 3.889vw 0 6.25vw;
          background-size: 90%;
        }
      `
    : css`
        width: 100%;
        height: 100%;
        background-color: #191d22;
        min-height: 100vh;
        padding: 1.111vw 0 16vw;
        @media (min-width: 769px) {
          padding: 0 0 6.25vw 0;
          background-color: #090d12;
        }
      `;
};

export const Container = styled.div`
  padding: ${({ $isMain }) => ($isMain ? '0 4.267vw' : ' 0 5.333vw')};
  @media (min-width: 769px) {
    background: ${({ $isMain }) =>
      $isMain ? 'rgba(255, 255, 255, 0.05)' : 'none'};
    padding: ${({ $isMain }) => ($isMain ? '2.778vw 6.944vw' : '0 6.944vw')};
    border-bottom: ${({ $isMain }) =>
      $isMain ? '1px solid rgba(255, 255, 255, 0.20)' : 'none'};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ $isMain }) => getIsMainWrapper($isMain)}
  position: relative;
`;
