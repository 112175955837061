import { CompactTable } from '@table-library/react-table-library/compact';
import styled from 'styled-components';

const ColumnTitle = styled.p`
  color: var(--color-neutral-3);
  font-size: 4.25vw;
  white-space: wrap;
  text-align: center;
  @media (min-width: 769px) {
    font-size: 1.25vw;
  }
`;

const SectionTitle = styled.p`
  color: var(--color-neutral-3);
  font-size: 4vw;
  white-space: wrap;
  @media (min-width: 769px) {
    font-size: 1vw;
  }
`;

const RowText = styled.p`
  color: var(--color-neutral-2);
  font-size: 4vw;
  white-space: wrap;
  @media (min-width: 769px) {
    font-size: 1vw;
  }
`;

const ListItem = styled.li`
  list-style: disc;
  font-size: 4vw;

  @media (min-width: 769px) {
    font-size: 1vw;
  }
`;

const Link = styled.a`
  color: var(--color-primary-5);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  font-size: 4vw;
  @media (min-width: 769px) {
    font-size: 1vw;
  }
`;

const nodes = [
  {
    id: '0',
    row0: <SectionTitle>Account creation</SectionTitle>,
  },
  {
    id: '1',
    row0: <SectionTitle>Account creation</SectionTitle>,
    row1: (
      <RowText>
        When you download our mobile app, Haqabi Wallet, we process specific
        information to enable the creation of an account, verify subsequent
        logins and help you recover your account.
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Performance of a contract</RowText>
        <RowText>Article 6(1)(b) of the GDPR</RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>phone number</RowText>
        </ListItem>
        <ListItem>
          <RowText>nickname of social media / email</RowText>
        </ListItem>
        <ListItem>
          <RowText>user_id</RowText>
        </ListItem>
        <ListItem>
          <RowText>Geo IP</RowText>
        </ListItem>
        <ListItem>
          <RowText>IP for notifications</RowText>
        </ListItem>
        <ListItem>
          <RowText>FCM token</RowText>
        </ListItem>
        <ListItem>
          <RowText>Device ID</RowText>
        </ListItem>
        <ListItem>
          <RowText>ID_wallet</RowText>
        </ListItem>
        <ListItem>
          <RowText>Addresses of crypto wallets</RowText>
        </ListItem>
        <ListItem>
          <RowText>User agent</RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>Until account deletion</RowText>,
  },
  {
    id: '2',
    row0: <SectionTitle>Transactions</SectionTitle>,
  },
  {
    id: '3',
    row0: <SectionTitle>Making a transaction</SectionTitle>,
    row1: (
      <RowText>
        We enable transactions within the mobile app for users to make token
        swapping possible.
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Performance of a contract</RowText>
        <RowText>Article 6(1)(b) of the GDPR</RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>user_id</RowText>
        </ListItem>
        <ListItem>
          <RowText>FCM token</RowText>
        </ListItem>
        <ListItem>
          <RowText>ID_wallet</RowText>
        </ListItem>
        <ListItem>
          <RowText>Addresses of crypto wallets</RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>Until account deletion</RowText>,
  },
  {
    id: '4',
    row0: <SectionTitle>Updating balances</SectionTitle>,
    row1: (
      <RowText>
        We do this to display current balances and receive transactions within
        the app
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Performance of a contract</RowText>
        <RowText>Article 6(1)(b) of the GDPR</RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>user_id</RowText>
        </ListItem>
        <ListItem>
          <RowText>FCM token</RowText>
        </ListItem>
        <ListItem>
          <RowText>ID_wallet</RowText>
        </ListItem>
        <ListItem>
          <RowText>Addresses of crypto wallets</RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>Until account deletion</RowText>,
  },
  {
    id: '5',
    row0: (
      <SectionTitle>Buying crypto by fiat (onramp transactions)</SectionTitle>
    ),
    row1: (
      <RowText>
        This enables the purchase of crypto by fiat within the app
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Performance of a contract</RowText>
        <RowText>Article 6(1)(b) of the GDPR</RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>user_id</RowText>
        </ListItem>
        <ListItem>
          <RowText>FCM token</RowText>
        </ListItem>
        <ListItem>
          <RowText>ID_wallet</RowText>
        </ListItem>
        <ListItem>
          <RowText>Addresses of crypto wallets</RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>Until account deletion</RowText>,
  },
  { id: '6', row0: <SectionTitle>Support</SectionTitle> },
  {
    id: '7',
    row0: <RowText>Handling error cases</RowText>,
    row1: (
      <RowText>
        This allows us to log problems that users encounter within the app,
        monitor and resolve them.
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Legitimate interest</RowText>
        <RowText>Article 6(1)(f) of the GDPR</RowText>
        <RowText>
          We handle error cases in our legitimate interest to ensure that our
          product runs efficiently.
        </RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>phone number</RowText>
        </ListItem>
        <ListItem>
          <RowText>nickname of social media / email</RowText>
        </ListItem>
        <ListItem>
          <RowText>user_id</RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>90 days</RowText>,
  },
  {
    id: '8',
    row0: <RowText>Responding to customer requests</RowText>,
    row1: (
      <RowText>
        This allows us to respond to specific support requests from users of the
        Haqabi Wallet
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Performance of a contract</RowText>
        <RowText>Article 6(1)(b) of the GDPR</RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>phone number</RowText>
        </ListItem>
        <ListItem>
          <RowText>nickname of social media / email</RowText>
        </ListItem>
        <ListItem>
          <RowText>user_id</RowText>
        </ListItem>
        <ListItem>
          <RowText>Geo IP</RowText>
        </ListItem>
        <ListItem>
          <RowText>IP for notifications</RowText>
        </ListItem>
        <ListItem>
          <RowText>FCM token</RowText>
        </ListItem>
        <ListItem>
          <RowText>Device ID</RowText>
        </ListItem>
        <ListItem>
          <RowText>ID_wallet</RowText>
        </ListItem>
        <ListItem>
          <RowText>Addresses of crypto wallets</RowText>
        </ListItem>
        <ListItem>
          <RowText>Transactions of crypto assets</RowText>
        </ListItem>
        <ListItem>
          <RowText>User agent</RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>26 months</RowText>,
  },

  {
    id: '9',
    row0: <RowText>In-App analytics</RowText>,
    row1: (
      <RowText>
        Analytics help us to understand user behaviour and improve our product
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Legitimate interest</RowText>
        <RowText>Article 6(1)(f) of the GDPR</RowText>
        <RowText>
          We analyse app usage in our interest to understand what areas of the
          product require improvement and fixes.
        </RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>user_id</RowText>
        </ListItem>
        <ListItem>
          <RowText>user navigation</RowText>
        </ListItem>
        <ListItem>
          <RowText>Model of mobile device</RowText>
        </ListItem>
        <ListItem>
          <RowText>Platform version used by a user</RowText>
        </ListItem>
        <ListItem>
          <RowText>Geolocation - country of user</RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>180 days</RowText>,
  },
  {
    id: '10',
    row0: <SectionTitle>Website, Analytics & Advertisement</SectionTitle>,
  },
  {
    id: '11',
    row0: <RowText>Analysing in-app traffic</RowText>,
    row1: (
      <RowText>
        We analyse traffic to understand how users find our mobile app and to
        understand user behaviour for landing page improvement
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Consent</RowText>
        <RowText>Article 6(1)(a) of the GDPR</RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>Traffic sources</RowText>
        </ListItem>
        <ListItem>
          <RowText>Cookies</RowText>
        </ListItem>
        <ListItem>
          <RowText>Model of mobile device</RowText>
        </ListItem>
        <ListItem>
          <RowText>Platform version used by a user</RowText>
        </ListItem>
        <ListItem>
          <RowText>
            Geolocation - country of user Users &apos; actions with the ads
          </RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>180 days</RowText>,
  },
  {
    id: '12',
    row0: <RowText>Analysing website traffic on landing page</RowText>,
    row1: (
      <RowText>
        We analyse traffic to understand user behaviour for landing page
        improvement
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Consent</RowText>
        <RowText>Article 6(1)(a) of the GDPR</RowText>
        <RowText>
          Consenting to analytics cookies helps us achieve this.
        </RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>Traffic sources</RowText>
        </ListItem>
        <ListItem>
          <RowText>Cookies</RowText>
        </ListItem>
        <ListItem>
          <RowText>Model of mobile device</RowText>
        </ListItem>
        <ListItem>
          <RowText>Platform version used by a user</RowText>
        </ListItem>
        <ListItem>
          <RowText>
            Geolocation - country of user Users &apos; actions with the ads
          </RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>26 months</RowText>,
  },
  {
    id: '13',
    row0: <RowText>Facebook ads analytics</RowText>,
    row1: (
      <RowText>
        This allows us combine and analyse Facebook data to assess ad
        effectiveness and ROI
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Legitimate interest</RowText>
        <RowText>Article 6(1)(f) of the GDPR</RowText>
        <RowText>
          We conduct analytics in our interest to ensure that ads are effective,
          measure effectiveness, increase brand awareness and increase our
          customer base.
        </RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>Traffic sources</RowText>
        </ListItem>
        <ListItem>
          <RowText>Cookies</RowText>
        </ListItem>
        <ListItem>
          <RowText>Model of mobile device</RowText>
        </ListItem>
        <ListItem>
          <RowText>Platform version used by a user</RowText>
        </ListItem>
        <ListItem>
          <RowText>
            Geolocation - country of user Users &apos; actions with the ads
          </RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>180 days</RowText>,
  },
  {
    id: '14',
    row0: <RowText>Google ads analytics</RowText>,
    row1: (
      <RowText>This allows us to combine and analyse user google data</RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Legitimate interest</RowText>
        <RowText>Article 6(1)(f) of the GDPR</RowText>
        <RowText>
          We conduct analytics in our interest to ensure that ads are effective,
          measure effectiveness, increase brand awareness and increase our
          customer base.
        </RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>Traffic sources</RowText>
        </ListItem>
        <ListItem>
          <RowText>Cookies</RowText>
        </ListItem>
        <ListItem>
          <RowText>Model of mobile device</RowText>
        </ListItem>
        <ListItem>
          <RowText>Platform version used by a user</RowText>
        </ListItem>
        <ListItem>
          <RowText>
            Geolocation - country of user Users &apos; actions with the ads
          </RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>180 days</RowText>,
  },
  {
    id: '15',
    row0: (
      <RowText>
        Addressing enquiries sent to{' '}
        <Link href="mailto:support@haqabi.com">support@haqabi.com</Link> and
        <Link href="mailto:info@haqabi.com"> info@haqabi.com</Link>
      </RowText>
    ),
    row1: (
      <RowText>
        When we receive a message or support request, we process certain
        information in order to respond to the request for support or more
        information.
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Legitimate interest</RowText>
        <RowText>Article 6(1)(f) of the GDPR</RowText>
        <RowText>
          We address enquiries in our interest to ensure customer satisfaction
          or respond to potential customers or other interested parties.
        </RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>Email address</RowText>
        </ListItem>
        <ListItem>
          <RowText>Name and</RowText>
        </ListItem>
        <ListItem>
          <RowText>Email message content</RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>180 days</RowText>,
  },
  // ask Katya about 2nd point
  {
    id: '16',
    row0: <RowText>Advertising through social media influencers</RowText>,
    row1: (
      <RowText>
        This allows us to place adverts with influencers to reach a wider
        audience
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Legitimate interest</RowText>
        <RowText>Article 6(1)(f) of the GDPR</RowText>
        <RowText>
          We advertise with influencers in our interest to reach a wider
          audience, increase our customer base and promote brand awareness.
        </RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>Name of influencer</RowText>
        </ListItem>
        <ListItem>
          <RowText>Social media handle</RowText>
        </ListItem>
        <ListItem>
          <RowText>Number of followers</RowText>
        </ListItem>
        <ListItem>
          <RowText>Niche</RowText>
        </ListItem>
        <ListItem>
          <RowText>Social media platform</RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>180 days</RowText>,
  },
  { id: '17', row0: <SectionTitle>Financial reporting</SectionTitle> },
  {
    id: '18',
    row0: <RowText>Aggregating user data for financial reports</RowText>,
    row1: (
      <RowText>
        We aggregate financial data for analytics (trends, metrics, AOV, LTV)
        for financial reporting purposes.
      </RowText>
    ),
    row2: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <RowText>Legitimate interest</RowText>
        <RowText>Article 6(1)(f) of the GDPR</RowText>
        <RowText>
          We do this in our legitimate interest to ensure transparency by
          providing financial reports to stakeholders and for internal planning.
        </RowText>
      </div>
    ),
    row3: (
      <ul>
        <ListItem>
          <RowText>user_id</RowText>
        </ListItem>
        <ListItem>
          <RowText>Addresses of crypto wallets</RowText>
        </ListItem>
        <ListItem>
          <RowText>Transactions of the crypto assets</RowText>
        </ListItem>
        <ListItem>
          <RowText>
            Transactions of the fiat assets (only amounts, without any personal
            information)
          </RowText>
        </ListItem>
      </ul>
    ),
    row4: <RowText>26 months</RowText>,
  },
];

const TableContainer = styled.div`
  margin: 0 auto;
  overflow-x: scroll;

  table {
    border-collapse: collapse;
    min-width: 1200px;
  }
  @media (min-width: 769px) {
    table {
      width: 100%;
      min-width: auto;
    }
    overflow: auto;
  }

  th,
  td {
    padding: 2.674vw 5.348vw;
    @media (min-width: 769px) {
      padding: 0.694vw 1.389vw;
    }
    border: 1px solid #ddd;
    text-align: left;
  }

  th div,
  td div {
    overflow: visible;
  }

  th {
    background-color: #090d12;
    font-weight: bold;
  }

  td {
    background-color: #090d12;
  }

  tr:nth-child(1) td,
  tr:nth-child(3) td,
  tr:nth-child(7) td,
  tr:nth-child(11) td,
  tr:nth-child(18) td {
    background-color: var(--color-neutral-6);
    border: none;
    white-space: nowrap;
  }

  tr:hover {
    background-color: #090d12;
  }

  td:first-child,
  th:first-child {
    border-left: 0;
  }

  td:last-child,
  th:last-child {
    border-right: 0;
  }
`;

const COLUMNS = [
  {
    label: <ColumnTitle>Processing activity</ColumnTitle>,
    renderCell: item => item?.row0,
  },
  {
    label: <ColumnTitle>General description of purposes</ColumnTitle>,
    renderCell: item => item?.row1,
  },
  {
    label: <ColumnTitle>Lawful basis</ColumnTitle>,
    renderCell: item => item?.row2,
  },
  {
    label: <ColumnTitle>Categories of data</ColumnTitle>,
    renderCell: item => item?.row3,
  },
  {
    label: <ColumnTitle>Retention period</ColumnTitle>,
    renderCell: item => item?.row4,
  },
];

const Table = () => {
  const data = { nodes };
  return (
    <TableContainer>
      <CompactTable columns={COLUMNS} data={data} />
    </TableContainer>
  );
};

export default Table;
