import styled from 'styled-components';
import { ReactComponent as SuccessCheckIcon } from 'src/assets/icons/success-check.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-menu.svg';
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5.333vw;
`;

const Modal = styled.div`
  padding: 8.533vw 6.4vw 6.4vw 6.4vw;
  background: rgba(41, 49, 62, 0.24);
  border: 0.267vw solid var(--color-neutral-10);
  border-radius: 3.2vw;
  text-align: center;
  position: relative;
`;
const Title = styled.p`
  color: var(--color-neutral-1);
  font-family: Inter, sans-serif;
  font-size: 4.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: 122.222%;
  margin-top: 10.667vw;
`;

const SubTitle = styled.p`
  color: var(--color-neutral-1);
  font-family: Inter, sans-serif;
  font-size: 4.267vw;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-top: 3.2vw;
`;
const Button = styled.p`
  color: var(--color-primary-5);
  font-family: Inter, sans-serif;
  font-size: 4.267vw;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  width: 100%;
  padding: 2.133vw 0;
  margin-top: 10.667vw;
`;

export const SuccessModal = ({ setOpenPopup }) => {
  return (
    <ModalWrapper>
      <Modal>
        <CloseIcon
          color="var(--color-neutral-9)"
          style={{
            position: 'absolute',
            top: '4.267vw',
            right: '3.2vw',
          }}
          onClick={() => setOpenPopup(false)}
        />
        <SuccessCheckIcon width={'28vw'} height={'28vw'} />
        <Title>Congratulations!</Title>
        <SubTitle>
          You have been added to the waitlist. More details will follow once the
          launch date is set.
        </SubTitle>
        <Button onClick={() => setOpenPopup(false)}>OK</Button>
      </Modal>
    </ModalWrapper>
  );
};
