import { ReactComponent as BankIcon } from 'src/assets/icons/bank.svg';
import { ReactComponent as ShieldIcon } from 'src/assets/icons/shield.svg';
import { ReactComponent as StarsIcom } from 'src/assets/icons/stars.svg';
import {
  ContentBlockStyled,
  ContentBlockSubTitleStyled,
  ContentBlockTitleStyled,
  ContentBlocksStyled,
  ContentSubTitleStyled,
  ContentTitleStyled,
} from './styled';
import { Container } from 'src/components/Container/styled';

export const ProtectionDesktop = () => {
  return (
    <Container id="ProtectionDesktop" style={{ marginBottom: '8.333vw' }}>
      <ContentTitleStyled>Empowering Ethical Investing</ContentTitleStyled>
      <ContentSubTitleStyled>
        Building trust and inclusivity in the crypto world through fraud
        protection and support for Halal tokens
      </ContentSubTitleStyled>

      <ContentBlocksStyled>
        <ContentBlockStyled>
          <ShieldIcon style={{ width: '3.333vw', height: '3.333vw' }} />
          <ContentBlockTitleStyled>Fraud Protection</ContentBlockTitleStyled>

          <ContentBlockSubTitleStyled>
            Haqabi tracks fraudulent transactions and provides users with token
            ratings, as well as price analytics. This helps users make informed
            decisions and avoid fraudulent schemes
          </ContentBlockSubTitleStyled>
        </ContentBlockStyled>
        <ContentBlockStyled>
          <StarsIcom style={{ width: '3.333vw', height: '3.333vw' }} />
          <ContentBlockTitleStyled>
            Support for Halal Tokens
          </ContentBlockTitleStyled>

          <ContentBlockSubTitleStyled>
            Considering the cultural and religious characteristics of residents
            in developing countries, Haqabi introduces a special badge in the
            application that highlights halal tokens
          </ContentBlockSubTitleStyled>
        </ContentBlockStyled>
      </ContentBlocksStyled>
    </Container>
  );
};
