import React from 'react';
import cx from 'classnames';
import useIntl from 'src/components/CMS/intl/useIntl';

const Text = (
  {
    className,
    tag = 'div',
    font = 'sf-ui',
    message,
    size,
    color = 'neutral-1',
    html,
    children,
    onClick,
    ...otherProps
  },
  ref,
) => {
  const intl = useIntl();

  if (onClick && tag !== 'button') {
    throw new Error(
      'You can\'t use "onClick" without passing tag === "button". Create components ADA friendly!',
    );
  }

  const rootClassName = cx(
    [`text-${size}`],
    [`text-font-${font}`],
    [`color-${color}`],
    className,
  );

  let content = children;

  if (message) {
    if (typeof message === 'string') {
      content = message;
    } else {
      const { values, ...intlMessage } = message || {};

      content = intl.formatMessage(intlMessage, values);
    }
  }

  const htmlProps = {
    ...otherProps,
    ref,
    onClick,
    className: rootClassName,
  };

  if (html) {
    return React.createElement(tag, {
      ...htmlProps,
      dangerouslySetInnerHTML: {
        __html: content,
      },
    });
  }

  return React.createElement(tag, htmlProps, content);
};

Text.displayName = 'Text';

export default React.memo(React.forwardRef(Text));
