import { Container, Wrapper } from 'src/components/Container/styled';
import { Footer } from 'src/components/Footer';
import { FooterDesktop } from 'src/components/FooterDesktop';
import { Header } from 'src/components/Header';
import { HeaderDesktop } from 'src/components/HeaderDesktop';
import { useDeviceType } from 'src/hooks/useDeviceType';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ScrollToTopIcon } from 'src/assets/icons/scroll.svg';
import {
  BottomDescription,
  DataItemPoint,
  DataItemPoints,
  DataItemStyled,
  DataItemTitle,
  DataWrapperStyled,
  DescriptionStyled,
  HaqabiWalletTeam,
  ScrollToTop,
  TitleStyled,
  UpdateInfoStyled,
} from './styled';

const data = [
  {
    title: '1. Acceptance of Terms',
    points: [
      // eslint-disable-next-line react/jsx-key
      <span>
        1.1. By accessing or using the Haqabi Wallet website (
        <span style={{ color: '#AADE16' }}>haqabi.com</span>), you agree to be
        bound by these Terms of Use.
      </span>,
      '1.2. If you do not agree with any part of these terms, please do not use our website or services.',
    ],
  },
  {
    title: '2. Use of Services',
    points: [
      '2.1. You may use our website and services solely for personal and non-commercial purposes.',
      '2.2. You agree not to use the website for any illegal or unauthorized purpose.',
      '2.3. Haqabi Wallet reserves the right to modify or discontinue any aspect of the website and services at any time.',
    ],
  },
  {
    title: '3. Content and Intellectual Property',
    points: [
      '3.1. All content on the Haqabi Wallet website, including text, graphics, logos, images, and software, is the property of Haqabi wallet or its licensors and is protected by copyright laws.',
      '3.2. You may not reproduce, distribute, modify, or create derivative works from any content without our express written consent.',
    ],
  },
  {
    title: '4. Privacy',
    points: [
      '4.1. Your use of our website is subject to our Privacy Policy. Please review the policy to understand our data collection and usage practices.',
      '4.2. You may not reproduce, distribute, modify, or create derivative works from any content without our express written consent.',
    ],
  },
  {
    title: '5. Prohibited Conduct',
    points: [
      '5.1. You agree not to engage in any conduct that may interfere with the proper functioning of our website or services.',
      '5.2. Prohibited conduct includes but is not limited to hacking, data mining, or any action that imposes an unreasonable burden on our infrastructure.',
    ],
  },
  {
    title: '6. Third-Party Links',
    points: [
      '6.1. Our website may contain links to third-party websites for additional resources or information.',
      '6.2. Haqabi Wallet does not endorse or control the content of these third-party websites and is not responsible for their practices.',
    ],
  },
  {
    title: '7. Disclaimer of Warranties',
    points: [
      '7.1. Haqabi Wallet provides its services on an "as-is" and "as-available" basis. We do not guarantee the accuracy, completeness, or timeliness of our services.',
    ],
  },
  {
    title: '8. Limitation of Liability',
    points: [
      '8.1. Haqabi Wallet and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our website or services.',
    ],
  },
  {
    title: '9. Governing Law',
    points: [
      '9.1. These Terms of Use are governed by and construed in accordance with the laws of Republic of Seychelles. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in Republic of Seychelles.',
    ],
  },
  {
    title: '10. Changes to Terms',
    points: [
      '10.1. Haqabi Wallet reserves the right to update or modify these Terms of Use at any time. Changes will be effective immediately upon posting to the website.',
      '10.2. Your continued use of the website after changes are posted constitutes your acceptance of the modified terms.',
    ],
  },
  {
    title: '11. Contact Information',
    points: [
      // eslint-disable-next-line react/jsx-key
      <span>
        11.1. For any inquiries or concerns regarding these Terms of Use, please
        contact us at{' '}
        <span style={{ color: '#AADE16' }}>support@haqabi.com</span>
      </span>,
    ],
  },
];

function TermsAndUse() {
  const { isMobile } = useDeviceType();
  const [scrollVisible, setScrollVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      if (isMobile && window.scrollY > (windowHeight / 100) * 5) {
        setScrollVisible(true);
      } else {
        setScrollVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  return (
    <Wrapper>
      {scrollVisible && (
        <ScrollToTop onClick={() => window.scrollTo(0, 0)}>
          <ScrollToTopIcon width={'12.267vw'} height={'12.267vw'} />
        </ScrollToTop>
      )}
      <div style={{ flex: 1 }}>
        {isMobile ? <Header isMain /> : <HeaderDesktop isMain={true} />}
        <Container>
          <TitleStyled>Terms of Use</TitleStyled>
          <UpdateInfoStyled>Last updated December 2023</UpdateInfoStyled>
          <DescriptionStyled>
            Welcome to Haqabi Wallet! <br /> We are excited to have you on
            board. Before you dive into the innovative world of our
            decentralized mobile crypto wallet, please take a moment to review
            and understand our Terms of Use. By accessing or using our website,
            you agree to comply with and be bound by the following terms and
            conditions:
          </DescriptionStyled>
          <DataWrapperStyled>
            {data.map(item => (
              <DataItemStyled key={item.title}>
                <DataItemTitle>{item.title}</DataItemTitle>
                <DataItemPoints>
                  {item.points.map(point => (
                    <DataItemPoint key={point}>{point}</DataItemPoint>
                  ))}
                </DataItemPoints>
              </DataItemStyled>
            ))}
          </DataWrapperStyled>
          <BottomDescription>
            Thank you for choosing Haqabi Wallet. We look forward to providing
            you with a secure and user-friendly crypto experience!
          </BottomDescription>
          <HaqabiWalletTeam>Haqabi wallet team</HaqabiWalletTeam>
        </Container>
      </div>
      {isMobile ? <Footer isMain /> : <FooterDesktop isMain />}
    </Wrapper>
  );
}

export default TermsAndUse;
