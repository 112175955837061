import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const ContentWrapperStyled = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: space-between;
  border: 0.069vw solid #525a67;
  border-radius: 1.111vw;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: 9.722vw;
  padding: 3.611vw 3.611vw 0;
`;

export const ContentContainerStyled = styled.div`
  flex: 1;
`;

export const ContentTitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 2.222vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 1.944vw;
  white-space: nowrap;
`;

export const ContentDescriptionStyled = styled.p`
  color: var(--color-neutral-1);
  font-family: Inter, sans-serif;
  font-size: 1.736vw;
  font-weight: 00;
  line-height: 1.933vw;
  margin-bottom: 2.778vw;
`;

export const InputStyled = styled.input`
  width: 100%;
  padding: 1.111vw 0.735vw;
  border-radius: 0.833vw;
  border: 0.069vw solid rgba(255, 255, 255, 0.23);
  background: rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(0.556vw);
  color: #fff;
  font-size: 1.111vw;
  caret-color: #b6e925;
  margin-top: 1.389vw;

  &::placeholder {
    color: #979eaa;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.009vw;
  }
  &:focus {
    border-color: #b6e925;
    outline: none;
  }
`;

export const CheckboxTitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 0.903vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.01vw;
`;

export const CheckboxLinkStyled = styled(Link)`
  color: #aade16;
  cursor: pointer;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.01vw;
  text-decoration: none;
`;

export const ButtonStyled = styled.button`
  width: 100%;
  margin-top: 1.944vw;
  padding: 1.25vw 1.597vw;
  color: #10151b;
  font-family: Inter, sans-serif;
  font-size: 1.111vw;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.013vw;
  border-radius: 0.764vw;
  background: #aade16;
  border: none;
  cursor: pointer;
  &:active {
    transform: scale(1.01);
  }
`;

export const ImageStyled = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const isError = error => {
  if (error) {
    return css`
      input + label::before {
        content: '';
        border-color: #f85757;
        background-color: transparent;
        width: 1.111vw;
        height: 1.111vw;
      }
    `;
  }
  return css`
    input:checked + label::before {
      content: ${() =>
        `url( 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="${
          window.innerWidth / (1440 / 16)
        }" height="${
          window.innerWidth / (1440 / 16)
        }" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M12.4787 5.79794L6.82349 11.6224L3.52344 8.24381L4.47727 7.31216L6.82063 9.71132L11.522 4.86914L12.4787 5.79794Z" fill="%23171D25" /%3E%3C/svg%3E')`};
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #aade16;
      width: 1.111vw;
      height: 1.111vw;
    }
  `;
};

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.833vw;
  width: fit-content;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  label::before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.111vw;
    height: 1.111vw;
    border: 0.069vw solid rgba(170, 222, 22, 0.75);
    border-radius: 0.167vw;
    margin-right: 0.694vw;
  }

  ${props => isError(props.$error)}
`;

export const TextStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 1.389vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 1.042vw;
  opacity: 0.8;
`;

export const PrimaryTextStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 2.778vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 1.111vw;
`;

export const ErrorTextStyled = styled.p`
  font-size: 0.903vw;
  color: #f85757;
  margin-top: 0.556vw;
  font-family: Inter, sans-serif;
  line-height: 100%;
`;
