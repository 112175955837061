import { Container } from 'src/components/Container/styled';
import { ReactComponent as IslmIcon } from 'src/assets/icons/islm.svg';
import { ReactComponent as StakingIcon } from 'src/assets/icons/staking.svg';
import { ReactComponent as CoinsHeadIcon } from 'src/assets/icons/coins-hand.svg';
import { ReactComponent as RecoveryIcon } from 'src/assets/icons/recovery.svg';
import { ReactComponent as DataFlowIcon } from 'src/assets/icons/data-flow.svg';
import {
  BlockStyled,
  BlockWrapperStyled,
  BoxDescriptionStyled,
  ContentContainerStyled,
  ContentDescriptionStyled,
  ContentTitleStyled,
  ContentWrapperStyled,
  TitleStyled,
  ContentSubtitleStyled,
} from './styled';

export const FeaturesDesktop = () => {
  return (
    <Container>
      <TitleStyled id="Features">Features</TitleStyled>
      <ContentWrapperStyled>
        <ContentContainerStyled>
          <RecoveryIcon width={'3.889vw'} height={'3.889vw'} />,
          <ContentTitleStyled>Seedless recovery</ContentTitleStyled>
          <ContentDescriptionStyled>
            You can recover your wallet without memorizing a 12-word secret
            phrase. Even if you lose your phone, smart algorithms will help you
            restore access.
          </ContentDescriptionStyled>
        </ContentContainerStyled>
      </ContentWrapperStyled>
      <BlockWrapperStyled>
        <BlockStyled>
          <IslmIcon width={'2.778vw'} height={'2.778vw'} />
          <ContentSubtitleStyled>Works with ISLM</ContentSubtitleStyled>
          <BoxDescriptionStyled>
            Buy, store and swap Islamic Coin tokens ($ISLM) and other popular
            tokens
          </BoxDescriptionStyled>
        </BlockStyled>
        <BlockStyled>
          <DataFlowIcon width={'2.778vw'} height={'2.778vw'} />
          <ContentSubtitleStyled>Non-custodial wallet</ContentSubtitleStyled>
          <BoxDescriptionStyled>
            Only you have access to your keys and assets.
            <br /> Your keys – your crypto!
          </BoxDescriptionStyled>
        </BlockStyled>
        <BlockStyled>
          <StakingIcon width={'2.778vw'} height={'2.778vw'} />
          <ContentSubtitleStyled>Staking</ContentSubtitleStyled>
          <BoxDescriptionStyled>
            Invest in reliable crypto-assets and earn 2-10% per annum
          </BoxDescriptionStyled>
        </BlockStyled>
        <BlockStyled>
          <CoinsHeadIcon width={'2.778vw'} height={'2.778vw'} />
          <ContentSubtitleStyled>Save money</ContentSubtitleStyled>
          <BoxDescriptionStyled>
            Built-in tools to minimize commissions for transfers automatically{' '}
          </BoxDescriptionStyled>
        </BlockStyled>
      </BlockWrapperStyled>
    </Container>
  );
};
