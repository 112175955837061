import React, { createContext, useContext, useState, useEffect } from 'react';
import { debounce } from './useDebounce';

const breakpoints = {
  sm: { min: 0, max: 480 },
  md: { min: 480, max: 1200 },
  lg: { min: 1200, max: 1920 },
  xl: { min: 1920, max: Number.MAX_SAFE_INTEGER },
};

const BreakpointContext = createContext(undefined);

export function useBreakpoint() {
  const context = useContext(BreakpointContext);
  if (context === undefined) {
    throw new Error('useBreakpoint must be used within a BreakpointProvider');
  }
  return context;
}

export function BreakpointProvider({ children }) {
  const [breakpoint, setBreakpoint] = useState({
    sm: false,
    md: false,
    lg: false,
    xl: false,
    min: 0,
    max: 0,
  });

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      const resizeReport = {
        sm: false,
        md: false,
        lg: false,
        xl: false,
        min: 0,
        max: 0,
      };

      Object.keys(breakpoints).forEach(typeKey => {
        const range = breakpoints[typeKey];
        //@ts-ignore
        resizeReport[typeKey] = width > range.min;
        if (resizeReport[typeKey]) {
          resizeReport.min = range.min;
          resizeReport.max = range.max;
        }
      });

      setBreakpoint(resizeReport);
    }
    const debouncedHandleResize = debounce(handleResize, 300);

    window.addEventListener('resize', debouncedHandleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  );
}
