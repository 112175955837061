import { ReactComponent as Icons } from 'src/assets/icons/icons.svg';
import {
  DescriptionTextStyled,
  IconsInfoTextStyled,
  MainStyled,
  MainTextStyled,
  NetworkAdditionStyled,
  StoreItem,
  StoreText,
} from './styled';
import { Container } from 'src/components/Container/styled';
import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/play-store.svg';
import { ReactComponent as DownloadApkIcon } from 'src/assets/icons/download-apk.svg';
import { ReactComponent as AppStoreIcon } from 'src/assets/icons/app-store.svg';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <MainStyled>
        <MainTextStyled>
          <span style={{ color: 'var(--color-primary-5)' }}>Haqabi</span> #1
          decentralized crypto wallet
        </MainTextStyled>
        <DescriptionTextStyled>
          Where security meets simplicity
        </DescriptionTextStyled>
        <NetworkAdditionStyled>
          <Icons width={'31.467vw'} height={'7.733vw'} />

          <IconsInfoTextStyled $isMobile={true}>and more</IconsInfoTextStyled>
        </NetworkAdditionStyled>

        <StoreItemsWrapper>
          <StoreItem
            href="https://play.google.com/store/apps/details?id=com.haqabi.mobilewallet"
            target="_blank">
            <PlayMarketIcon width={'6.4vw'} height={'6.4vw'} />

            <StoreText>Google play</StoreText>
          </StoreItem>
          <StoreItem
            href="https://apps.apple.com/app/haqabi-wallet/id6477534740"
            target="_blank">
            <AppStoreIcon width={'6.4vw'} height={'6.4vw'} />

            <StoreText>App Store</StoreText>
          </StoreItem>
          <StoreItem onClick={() => navigate('/download-apk')}>
            <StoreText>APK</StoreText>
            <DownloadApkIcon width={'5.333vw'} height={'5.333vw'} />
          </StoreItem>
        </StoreItemsWrapper>
      </MainStyled>
    </Container>
  );
};

const StoreItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19.2vw;
  gap: 1.6vw;
`;
